/* istanbul ignore file */
import { TAuthConfig } from '@unbooking/ui-auth';

const federatedUserToken = sessionStorage.getItem('X-User-Token');

const scope = ['email', 'openid', 'profile'].join(' ');

const errorCodes = process.env.REACT_APP_AZURE_REFRESH_TOKEN_ERROR_CODES
  ? process.env.REACT_APP_AZURE_REFRESH_TOKEN_ERROR_CODES.split(',').map((code: string) =>
      Number(code),
    )
  : undefined;

export const authConfig: TAuthConfig = {
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID ?? '',
  authorizationEndpoint: process.env.REACT_APP_AUTH_AUTHORIZATION_ENDPOINT ?? '',
  autoAuthorize: false,
  skipInterceptors: !!federatedUserToken,
  unbhPermissionsEndpoint: '',
  tokenEndpoint: process.env.REACT_APP_AUTH_TOKEN_ENDPOINT ?? '',
  redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI ?? '',
  logoutEndpoint: process.env.REACT_APP_AUTH_LOGOUT_ENDPOINT ?? '',
  logoutUri: process.env.REACT_APP_AUTH_LOGOUT_URI ?? '',
  refreshTokenErrorCodes: errorCodes,
  scope,
  configurations: {
    b2c: {
      authorizationEndpoint: process.env.REACT_APP_B2C_AUTHORIZATION_ENDPOINT ?? '',
      tokenEndpoint: process.env.REACT_APP_B2C_TOKEN_ENDPOINT ?? '',
      clientId: process.env.REACT_APP_B2C_CLIENT_ID ?? '',
      logoutEndpoint: process.env.REACT_APP_B2C_LOGOUT_ENDPOINT ?? '',
      logoutUri: process.env.REACT_APP_B2C_LOGOUT_URI ?? '',
      scope: `${scope} offline_access`,
    },
    google: {
      authorizationEndpoint: process.env.REACT_APP_GOOGLE_AUTHORIZATION_ENDPOINT ?? '',
      tokenEndpoint: process.env.REACT_APP_GOOGLE_TOKEN_ENDPOINT ?? '',
      clientId: process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID ?? '',
      clientSecret: process.env.REACT_APP_GOOGLE_AUTH_CLIENT_SECRET ?? '',
      logoutUri: '/?is_google_user=true',
      scope,
    },
  },
};
