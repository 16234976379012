import type { FC } from 'react';
import { t } from 'i18next';
import { useFormApi } from 'informed';
import { Dropdown } from '@ui-modules/informed';
import { IDropdownOption } from '@ui-modules/types';
import { notEmptyValidator } from '@common/utils';

interface VehicleProtectionProps {
  disabled?: boolean;
  options: IDropdownOption[];
}

const VehicleProtectionField: FC<VehicleProtectionProps> = ({ disabled, options }) => {
  const formApi = useFormApi();

  return (
    <div className="field vehicle-protection">
      <Dropdown
        isDisabled={disabled}
        label={t('mobility.skinType')}
        name="protection"
        options={options}
        validate={notEmptyValidator}
        onChange={(option: IDropdownOption) => {
          if (option.value === 'armoured') formApi.setValue('sharedLocation', []);
        }}
      />
    </div>
  );
};

export default VehicleProtectionField;
