/* istanbul ignore file */
import { t } from 'i18next';
import { Dropdown } from '@ui-modules/informed';
import { IDropdownOption } from '@ui-modules/types';
import { notEmptyValidator } from '@common/utils';
import '../styles/modal.scss';

const PurposeField = ({ options }: { options: IDropdownOption[] }) => (
  <div className="field" data-testid="planner-drive-form-field-purpose">
    <Dropdown
      className="dropdown"
      label={t('common.purpose')}
      name="purpose"
      options={options}
      validate={notEmptyValidator}
    />
  </div>
);

export default PurposeField;
