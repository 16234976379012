import { TextField } from '@ui-modules/informed';
import { emailValidator } from '@common/utils';

const EmailField = ({ disabled, label = 'Email' }: { disabled?: boolean; label?: string }) => (
  <div className="field field-email">
    <TextField disabled={disabled} label={label} name="email" validate={emailValidator} />
  </div>
);

export default EmailField;
