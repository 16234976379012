import { useTranslation } from 'react-i18next';
import type { UseFormReturn } from 'react-hook-form';
import type { IDropdownOption } from '@ui-modules/types';
import { DateInputFormat, GoogleSuggestion } from '@common/types';
import { InputDatePicker, InputLocation, InputText, InputTimePicker, Select } from '@components';
import type { BFRoute } from '../types';

type SectionProps = {
  city?: string;
  country?: string;
  form: UseFormReturn<any>;
  purpose: IDropdownOption[];
  routes: BFRoute[];
  showGoogleLocation?: boolean;
  showTown?: boolean;
  onDateChange?: (idx: number) => void;
  onTimeChange?: (value: string, idx: number) => void;
};

const RoundTripSection = ({
  city,
  country,
  form,
  purpose,
  routes,
  showGoogleLocation,
  showTown,
  onDateChange,
  onTimeChange,
}: SectionProps) => {
  const { t } = useTranslation();
  const { control: ctrl, setValue, watch } = form;

  const setLegValues = (prefix: string, data: GoogleSuggestion) => {
    const { isFacilityLocation, lat, lng, town } = data.dataset || {};
    setValue?.(`${prefix}EqualFacility`, isFacilityLocation);
    setValue?.(`${prefix}Town`, town ?? city);
    setValue?.(`${prefix}Lat`, lat ?? '');
    setValue?.(`${prefix}Lng`, lng ?? '');
  };

  const handleLocationChange = (name: string, leg: number) => (e: GoogleSuggestion | string) => {
    if (typeof e === 'object') {
      setLegValues(`routes.${leg}.${name}`, e);

      if (leg === 0 && name === 'dropoff') {
        const nextLegPrefix = `routes.${leg + 1}.pickup`;
        setLegValues(`routes.${leg}.${name}`, e);
        setValue?.(`${nextLegPrefix}Location`, e.value);
      }
    }
  };

  const renderRoutes = (idx: number): JSX.Element => {
    const isDropoffLocExtraRequired = watch(`routes.${idx}.dropoffEqualFacility`);
    const isPickupLocExtraRequired = watch(`routes.${idx}.pickupEqualFacility`);

    return (
      <fieldset key={`route-${idx}`} data-testid={`bf-route-fieldset-${idx}`}>
        {(showGoogleLocation || showTown) && (
          <>
            <InputText
              name={`routes.${idx}.pickupTown`}
              className="field"
              control={ctrl}
              hidden={showGoogleLocation}
              label={t('bookingDetails.pickupTown')}
              required
              data-testid={`bf-route-pickup-town-${idx}`}
            />
            <InputText
              name={`routes.${idx}.dropoffTown`}
              className="field"
              control={ctrl}
              hidden={showGoogleLocation}
              label={t('bookingDetails.dropoffTown')}
              required
              data-testid={`bf-route-dropoff-town-${idx}`}
            />
          </>
        )}

        <legend>{idx ? t('bookingDetails.returnTrip') : t('bookingDetails.outwardTrip')}</legend>
        <InputDatePicker
          name={`routes.${idx}.pickupDate`}
          className="field"
          control={ctrl}
          hideOutsideDates
          label={t('bookingDetails.pickupDate')}
          minDate={new Date()}
          required
          valueFormat={DateInputFormat.DateByDots}
          onChange={() => onDateChange?.(idx)}
          data-testid={`bf-route-pickup-date-${idx}`}
        />
        <InputTimePicker
          name={`routes.${idx}.pickupTime`}
          className="field"
          control={ctrl}
          label={t('bookingDetails.pickupTime')}
          required
          onChange={(e) => onTimeChange?.(e.target.value, idx)}
          data-testid={`bf-route-pickup-time-${idx}`}
        />

        {showGoogleLocation ? (
          <>
            <InputLocation
              name={`routes.${idx}.pickupLocation`}
              className="field"
              control={ctrl}
              defaultCity={city}
              defaultCountry={country}
              label={`${t('bookingDetails.pickupAddress')} (Google Maps)`}
              required
              onChange={handleLocationChange('pickup', idx)}
              data-testid={`bf-route-pickup-location-${idx}`}
            />
            <InputLocation
              name={`routes.${idx}.dropoffLocation`}
              className="field"
              control={ctrl}
              defaultCity={city}
              defaultCountry={country}
              label={`${t('bookingDetails.dropoffAddress')} (Google Maps)`}
              required
              onChange={handleLocationChange('dropoff', idx)}
              data-testid={`bf-route-dropoff-location-${idx}`}
            />
            <InputText
              name={`routes.${idx}.pickupLocExtra`}
              className="field"
              control={ctrl}
              description={isPickupLocExtraRequired ? t('bookingForm.tooltipAddress') : ''}
              label={`${t('bookingDetails.pickupAddress')} (Manual Input${
                isPickupLocExtraRequired ? '' : ', optional'
              })`}
              required={isPickupLocExtraRequired}
            />
            <InputText
              name={`routes.${idx}.dropoffLocExtra`}
              className="field"
              control={ctrl}
              description={isDropoffLocExtraRequired ? t('bookingForm.tooltipAddress') : ''}
              label={`${t('bookingDetails.dropoffAddress')} (Manual Input${
                isDropoffLocExtraRequired ? '' : ', optional'
              })`}
              required={isDropoffLocExtraRequired}
            />
          </>
        ) : (
          <>
            <InputText
              name={`routes.${idx}.pickupLocation`}
              className="field"
              control={ctrl}
              label={t('bookingDetails.pickupLocation')}
              required
              data-testid={`bf-route-pickup-location-${idx}`}
            />
            <InputText
              name={`routes.${idx}.dropoffLocation`}
              className="field"
              control={ctrl}
              label={t('bookingDetails.dropoffLocation')}
              required
              data-testid={`bf-route-dropoff-location-${idx}`}
              onChange={(e) => {
                if (idx === 0) setValue?.(`routes.${idx + 1}.pickupLocation`, e.target?.value);
              }}
            />
          </>
        )}
      </fieldset>
    );
  };

  return (
    <>
      {Array.from({ length: routes?.length }, (_, i) => renderRoutes(i))}

      <fieldset data-testid="bf-extra-fieldset">
        <Select
          name="bookingExtra.purpose"
          className="field"
          control={ctrl}
          label={t('common.purpose')}
          options={purpose}
          required
          data-testid="bf-purpose"
        />
        <InputText
          name="bookingExtra.remarks"
          className="field"
          control={ctrl}
          label={`${t('common.remarks')} (${t('common.optional')})`}
          data-testid="bf-remarks"
        />
      </fieldset>
    </>
  );
};

export default RoundTripSection;
