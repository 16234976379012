import { type FC, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { Button, PageTitle, Tabs } from '@unbooking/ui-modules';
import { useFacility, useUserInfo } from '@common/hooks';
import { useRepository } from '@context';
import { ReportParams } from '@common/interfaces';
import { Download } from '@assets/svg/icons';
import { ReportDetails, Summary } from './components';
import './styles.scss';

const CostRecoveryReportPage: FC = () => {
  const {
    agencyName,
    facility: { city, country },
    facilityId,
  } = useFacility();
  const { t } = useTranslation();
  const { firstName, lastName } = useUserInfo();
  const { reportRepository } = useRepository();

  const tabs = ['Report Details', 'Summary'];

  const [selectedTab, setSelectedTab] = useState<string>(
    sessionStorage.getItem('selectedTabCostRecoveryReport') ?? tabs[0],
  );

  const { mutate: exportDrives, isLoading: fileLoading } = useMutation<
    unknown,
    AxiosError,
    ReportParams
  >('export-drives', (params: ReportParams) => reportRepository.exportDrives(facilityId, params), {
    onError: (error) => {
      if (error) toast.error(t('report.errorMsgDrives'));
    },
  });

  useEffect(
    () => () => {
      sessionStorage.removeItem('selectedTabCostRecoveryReport');
    },
    [],
  );

  return (
    <section className="hbh-container cost-recovery-report-page">
      <PageTitle
        title={`${t('report.costRecoveryTitle')} ${city}, ${country} | ${agencyName}`}
        bottomLine
        filters={
          <Button
            className="btn btn-export"
            disabled={fileLoading}
            form="report_filters_form"
            icon={<Download />}
            text={t('common.btnExportData')}
            type="submit"
            variant="submit"
          />
        }
      />

      <Tabs
        className="tabs"
        tabs={tabs}
        selectedTab={tabs.indexOf(selectedTab)}
        onTabSelect={setSelectedTab}
      />

      {selectedTab === tabs[0] && (
        <ReportDetails username={`${firstName} ${lastName}`} exportDrives={exportDrives} />
      )}
      {selectedTab === tabs[1] && (
        <Summary username={`${firstName} ${lastName}`} exportDrives={exportDrives} />
      )}
    </section>
  );
};

export default CostRecoveryReportPage;
