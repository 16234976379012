/* istanbul ignore file */
import { useEffect, useState } from 'react';
import { useFormApi } from 'informed';
import { t } from 'i18next';
import { IDropdownOption } from '@ui-modules/types';
import { Dropdown, TextField } from '@ui-modules/informed';
import { notEmptyValidator } from '@common/utils';
import { DriveFormSteps, ShuttleStop } from '@common/interfaces';
import { useFieldState } from '@common/hooks';
import '../styles/modal.scss';

interface FieldProps {
  choices?: { [x: string]: IDropdownOption[] };
  fieldGroup?: string;
  index?: number;
  isEdit?: boolean;
  options?: IDropdownOption[];
  returnTrip?: boolean;
  scheduled?: ShuttleStop[];
}

const DropoffLocationField = ({
  choices,
  fieldGroup,
  index,
  isEdit,
  options,
  returnTrip = false,
  scheduled,
}: FieldProps) => {
  const { getFormState, getValue, setValue } = useFormApi();
  const formState = getFormState().values as any;
  const { value: dropoffLocation } = useFieldState<IDropdownOption>('dropoffLocation');
  const { value: pickupLocation } = useFieldState<IDropdownOption>('pickupLocation');
  const drives = formState?.[DriveFormSteps.Trip]?.driveList;
  const pickupLoc = drives?.[index!]?.pickupLocation ?? null;
  const dropoffTown = fieldGroup ? `${fieldGroup}.dropoffTown` : 'dropoffTown';
  const dropoffTownCurrent = getValue(dropoffTown) as IDropdownOption;

  const [data, setData] = useState<IDropdownOption[]>([]);

  useEffect(() => {
    if (returnTrip && pickupLoc) {
      setValue(fieldGroup ? `${fieldGroup}.dropoffLocation` : 'dropoffLocation', pickupLoc);
    }
  }, [fieldGroup, pickupLoc, returnTrip, setValue]);

  useEffect(() => {
    let dropdown: IDropdownOption[] = [];

    if (options) {
      const locIdx = options.findIndex((i) => i.value === pickupLocation?.value);
      dropdown = options.slice(locIdx + 1);
    } else if (choices && dropoffTownCurrent && dropoffTownCurrent.value in choices) {
      dropdown = choices[dropoffTownCurrent.value];
    }

    setData(dropdown);

    if (
      scheduled &&
      dropoffLocation &&
      pickupLocation &&
      pickupLocation?.value === dropoffLocation?.value
    ) {
      setValue(fieldGroup ? `${fieldGroup}.dropoffLocation` : 'dropoffLocation', '');
      setValue(fieldGroup ? `${fieldGroup}.dropoffTown` : 'dropoffTown', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choices, dropoffLocation?.value, dropoffTownCurrent, options, pickupLocation?.value]);

  return (
    <div className="field" data-testid="planner-drive-form-field-dropoff-location">
      {choices || options?.length ? (
        <Dropdown
          className="dropdown"
          isClearable
          isCreatable={!!choices?.length || isEdit}
          label={t('bookingDetails.dropoffLocation')}
          name="dropoffLocation"
          options={data}
          placeholder={
            options ? undefined : `${t('common.select')} ${t('bookingDetails.dropoffTown')}...`
          }
          validate={notEmptyValidator}
          onChange={(option) => {
            if (scheduled) {
              const town = scheduled.find((i) => i.location === option?.value)?.town;
              setValue(fieldGroup ? `${fieldGroup}.dropoffTown` : 'dropoffTown', town);
            }
          }}
        />
      ) : (
        <TextField
          className="input"
          data-testid="planner-drive-form-field-dropoff-location"
          label={t('bookingDetails.dropoffLocation')}
          name="dropoffLocation"
          validate={notEmptyValidator}
        />
      )}
    </div>
  );
};

export default DropoffLocationField;
