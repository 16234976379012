import { useRef } from 'react';
import { type FieldValues, type UseControllerProps, useController } from 'react-hook-form';
import { type TimeInputProps, TimeInput } from '@mantine/dates';
import { ActionIcon } from '@mantine/core';
import { Clock } from '@assets/svg/icons/logbook';

export type InputTimePickerProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<TimeInputProps, 'defaultValue' | 'value'>;

export function InputTimePicker<T extends FieldValues>({
  control,
  defaultValue,
  name,
  rules,
  shouldUnregister,
  onChange,
  ...props
}: InputTimePickerProps<T>) {
  const {
    field: { ref, value, onChange: fieldOnChange, ...field },
    fieldState,
  } = useController<T>({
    control,
    defaultValue,
    name,
    rules,
    shouldUnregister,
  });

  const pickerRef = useRef<HTMLInputElement & { showPicker?: () => void }>(null);

  const pickerControl = (
    <ActionIcon variant="subtle" color="gray" onClick={() => pickerRef.current?.showPicker?.()}>
      <Clock />
    </ActionIcon>
  );

  return (
    <TimeInput
      error={fieldState.error?.message}
      ref={pickerRef}
      rightSection={pickerControl}
      value={value}
      onChange={(e) => {
        fieldOnChange(e);
        onChange?.(e);
      }}
      {...field}
      {...props}
    />
  );
}
