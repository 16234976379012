import type { FC } from 'react';
import { Dropdown } from '@ui-modules/informed';
import { IDropdownOption } from '@ui-modules/types';
import { notEmptyValidator } from '@common/utils';

interface VehiclePaxProps {
  disabled?: boolean;
  options: IDropdownOption[];
}

const VehiclePaxField: FC<VehiclePaxProps> = ({ disabled, options }) => (
  <div className="field vehicle-pax">
    <Dropdown
      isDisabled={disabled}
      label="Pax N°"
      name="maxCapacity"
      options={options}
      validate={notEmptyValidator}
    />
  </div>
);

export default VehiclePaxField;
