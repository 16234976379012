import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useGlobalContext, useRepository } from '@context';
import { FeatureFlags } from '@common/interfaces';
import useFacility from './useFacility';

const useFeatureFlags = (): FeatureFlags => {
  const { accountRepository } = useRepository();
  const { featureFlags, setFeatureFlags } = useGlobalContext();
  const { agencyName, facility, facilityId } = useFacility();

  const { data } = useQuery(
    'get-feature-flags',
    () => accountRepository.getFeatureFlags({ agencyName, facilityId }),
    { enabled: facility.isActive && Object.keys(featureFlags.flags).length === 0 },
  );

  useEffect(() => {
    if (data) {
      setFeatureFlags(data);
    }
  }, [data, setFeatureFlags]);

  return featureFlags;
};

export default useFeatureFlags;
