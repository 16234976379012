import { t } from 'i18next';
import { DateField } from '@ui-modules/informed';

const DriverExpirationDateField = ({ disabled }: { disabled?: boolean }) => (
  <div className="field field-expiration-date">
    <DateField
      dateFormat="dd/MM/yyyy"
      disabled={disabled}
      dropdownMode="select"
      isClearable
      label={t('mobility.expirationDate')}
      minDate={new Date()}
      name="licenseExpirationDate"
      showMonthDropdown
      showYearDropdown
    />
  </div>
);

export default DriverExpirationDateField;
