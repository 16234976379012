import { t } from 'i18next';
import { TextField } from '@ui-modules/informed';
import { notEmptyValidator } from '@common/utils';

const VehicleAgencyField = ({ disabled }: { disabled?: boolean }) => (
  <div className="field vehicle-agency">
    <TextField
      disabled={disabled}
      label={t('common.agency')}
      name="agency"
      validate={notEmptyValidator}
    />
  </div>
);

export default VehicleAgencyField;
