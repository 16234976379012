import { t } from 'i18next';
import { TextField } from '@ui-modules/informed';

const DriverLicenseNumberField = ({ disabled }: { disabled?: boolean }) => (
  <div className="field vehicle-plate-number">
    <TextField disabled={disabled} label={t('common.number')} max="12" name="licenseNumber" />
  </div>
);

export default DriverLicenseNumberField;
