import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button } from '@unbooking/ui-modules';
import { DriverType } from '@common/interfaces';
import { Blood, Copy, DriverLicense, DriverPlaceholder, Edit, ReadOnly } from '@assets/svg/icons';
import './styles.scss';

export interface DriverProps {
  driver: DriverType;
  isEditable?: boolean;
  isLoading?: boolean;
  onDriverEdit?: (driverId: string) => void;
  onDriverStatusChange?: (driver: DriverType) => void;
}

const Driver: FC<DriverProps> = ({
  driver,
  isEditable,
  isLoading,
  onDriverEdit,
  onDriverStatusChange,
}) => {
  const { t } = useTranslation();

  const {
    agency,
    bloodGroupDisplay,
    currentUnavailability,
    email,
    lastName,
    licenseExpirationDate,
    licenseNumber,
    name,
    phoneNumber,
    photo,
    sharedAgenciesCount: sharedCount,
  } = driver;

  const copyToClipboard = (str: string): void => {
    const textField = document.createElement('textarea');
    textField.innerText = str;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    toast.success('Copied to clipboard');
  };

  return (
    <div className="driver-wrap">
      <div className="driver">
        <div className="driver-photo">
          {photo ? (
            <img src={photo} alt={`${name} ${lastName}`} />
          ) : (
            <div className="driver-photo-placeholder" data-testid="driver-photo-placeholder">
              <DriverPlaceholder />
            </div>
          )}

          {currentUnavailability?.deleted && (
            <div className="driver-status">
              {t('common.disable')}
              <div className="vehicle-status-text">
                {`${t('common.from')} ${currentUnavailability?.sinceDate}`}
              </div>
            </div>
          )}

          {currentUnavailability?.sinceDate && currentUnavailability?.untilDate ? (
            <div className="driver-status">
              {t('mobility.unavailable')}
              <div className="driver-status-text">
                {`${t('common.from')} ${currentUnavailability?.sinceDate} ${t('common.to')} ${
                  currentUnavailability?.untilDate
                }`}
              </div>
            </div>
          ) : null}
        </div>

        <div className="driver-details">
          <div className="driver-details-agency">
            {agency?.logo ? <img src={agency?.logo} alt={agency?.shortName} /> : agency?.shortName}
          </div>
          <div className="driver-details-info">
            <div className="driver-details-info-title">
              <div className="name">
                {name} {lastName}
              </div>

              {sharedCount ? <div className="shared">{t('mobility.shared')}</div> : null}
            </div>

            {email && (
              <button className="email" type="button" onClick={() => copyToClipboard(email)}>
                {email}
                <div className="icon">
                  <Copy />
                </div>
              </button>
            )}

            {phoneNumber && (
              <button className="phone" type="button" onClick={() => copyToClipboard(phoneNumber)}>
                {phoneNumber}
                <div className="icon">
                  <Copy />
                </div>
              </button>
            )}

            {bloodGroupDisplay && (
              <div className="blood">
                <div className="icon">
                  <Blood />
                </div>
                ({bloodGroupDisplay})
              </div>
            )}
          </div>
        </div>

        <div className="driver-license">
          <div className="ctrl">
            <Button
              className="btn-edit"
              icon={isEditable ? <Edit /> : <ReadOnly />}
              text={isEditable ? t('common.btnEdit') : t('common.btnView')}
              variant="icon"
              onClick={() => onDriverEdit?.(driver.id)}
            />
          </div>

          <div className="driver-license-header">
            <DriverLicense />
            {t('mobility.driverLicense')}
          </div>

          <div className="driver-license-data">
            <div className="driver-license-number">
              <div className="label">{t('common.number')}</div>
              <div className="value">{licenseNumber}</div>
            </div>
            {licenseExpirationDate && (
              <div className="driver-license-expiration">
                <div className="label">{t('mobility.expirationDate')}</div>
                <div className="value">{licenseExpirationDate}</div>
              </div>
            )}
          </div>
        </div>
      </div>

      {isEditable && (
        <div className="driver-ctrl">
          {currentUnavailability ? (
            <Button
              className="btn btn-available"
              disabled={isLoading!}
              text={t('mobility.btnMarkAvailable')}
              variant="transparent"
              onClick={() => onDriverStatusChange?.(driver)}
            />
          ) : (
            <Button
              className="btn btn-unavailable"
              text={t('mobility.btnMarkUnavailable')}
              variant="transparent"
              onClick={() => onDriverStatusChange?.(driver)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Driver;
