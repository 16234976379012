import type { FC } from 'react';
import { t } from 'i18next';
import { TextField } from '@ui-modules/informed';
import { notEmptyValidator } from '@common/utils';

interface LastNameProps {
  disabled?: boolean;
  label?: string;
}

const LastNameField: FC<LastNameProps> = ({ disabled, label = t('common.lastName') }) => (
  <div className="field field-last-name">
    <TextField disabled={disabled} label={label} name="lastName" validate={notEmptyValidator} />
  </div>
);

export default LastNameField;
