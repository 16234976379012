import type { FC } from 'react';
import { Dropdown } from '@ui-modules/informed';
import { IDropdownOption } from '@ui-modules/types';

interface DriverSharedAgencyProps {
  disabled?: boolean;
  options?: IDropdownOption[];
}

const DriverSharedAgencyField: FC<DriverSharedAgencyProps> = ({ disabled, options }) => (
  <div className="field field-shared-agency">
    <Dropdown isDisabled={disabled} isMulti name="sharedLocations" options={options} />
  </div>
);

export default DriverSharedAgencyField;
