import { FC, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { format } from 'date-fns';

import { Button, PageTitle, Pagination } from '@unbooking/ui-modules';
import { useFacility, useFeatureFlags } from '@common/hooks';
import { useRepository } from '@context';
import { Serializer } from '@common/utils';
import { ApiList, DriverType, DriverApi, DriverCreate, DriverUpdate } from '@common/interfaces';
import { Driver, DriverEditForm, Modal, Loader, Tabs, UnavailabilityForm } from '@components';
import './styles.scss';

const DriversListPage: FC = () => {
  const { flags } = useFeatureFlags();
  const { mobilityRepository } = useRepository();
  const { t } = useTranslation();
  const { agencyName, facility, facilityId } = useFacility();
  const { agencyId, city, country } = facility;
  const tabs = ['My Drivers', 'Shared'];

  const [isLoadingProcess, setLoadingProcess] = useState<boolean>(false);
  const [driverUnavailable, setDriverUnavailable] = useState<DriverType | null>(null);
  const [selectedTab, setSelectedTab] = useState<string>(tabs[0]);
  const [selectedDriver, setSelectedDriver] = useState<string | null>(null);
  const [showDriverForm, setShowDriverForm] = useState<boolean>(false);

  const [driverList, setDriverList] = useState<DriverType[]>([]);
  const [driverTotalItems, setDriverTotalItems] = useState<number>(0);
  const [driverSelectedPage, setDriverSelectedPage] = useState<number>(1);
  const [driverPageSize, setDriverPageSize] = useState<number>(10);
  const [sharedDriverList, setSharedDriverList] = useState<DriverType[]>([]);
  const [sharedDriverTotalItems, setSharedDriverTotalItems] = useState<number>(0);
  const [sharedDriverSelectedPage, setSharedDriverSelectedPage] = useState<number>(1);
  const [sharedDriverPageSize, setSharedDriverPageSize] = useState<number>(10);

  const { isLoading: isDriversLoading, refetch: getDrivers } = useQuery(
    ['driver-list', driverPageSize, driverSelectedPage],
    () =>
      mobilityRepository.getDrivers(facilityId, {
        agencyId,
        limit: driverPageSize,
        offset: (driverSelectedPage - 1) * 10,
        ordering: 'name',
      }),
    {
      enabled: !!agencyId,
      onSuccess: (data: ApiList<DriverApi>) => {
        setDriverList(data.results.map(Serializer.formatDriver));
        setDriverTotalItems(data.count);
        setLoadingProcess(false);
      },
    },
  );

  const { isLoading: isSharedDriversLoading, refetch: getSharedDrivers } = useQuery(
    ['shared-driver-list', sharedDriverPageSize, sharedDriverSelectedPage],
    () =>
      mobilityRepository.getDrivers(
        facilityId,
        {
          agencyId,
          limit: sharedDriverPageSize,
          offset: (sharedDriverSelectedPage - 1) * 10,
          ordering: 'name',
        },
        true,
      ),
    {
      enabled: !!agencyId,
      onSuccess: (data: ApiList<DriverApi>) => {
        setSharedDriverList(data.results.map(Serializer.formatDriver));
        setSharedDriverTotalItems(data.count);
      },
    },
  );

  const { mutate: deleteUnavailabilities } = useMutation<
    unknown,
    AxiosError,
    { id: string; unavailabilityId: string }
  >(
    'delete-unavailabilities',
    ({ id, unavailabilityId }) =>
      mobilityRepository.deleteUnavailabilities(facilityId, id, unavailabilityId, 'driver'),
    {
      onSuccess: () => {
        if (selectedTab === tabs[0]) getDrivers();
        if (selectedTab === tabs[1]) getSharedDrivers();
      },
    },
  );

  const { isLoading: isDriverCreating, mutateAsync: createDriver } = useMutation<
    unknown,
    AxiosError,
    DriverCreate
  >('create-driver', (data) => mobilityRepository.createDriver(facilityId, data), {
    onSuccess: () => {
      toast.success(t('mobility.msgDriverUpdated'));
      setShowDriverForm(false);
      if (selectedTab === tabs[0]) getDrivers();
      if (selectedTab === tabs[1]) getSharedDrivers();
    },
    onError: (e: any) => {
      if (e.message) toast.error(e.response.data || t('common.errorMsgDefault'));
    },
  });

  const { isLoading: isDriverUpdating, mutateAsync: updateDriver } = useMutation<
    unknown,
    AxiosError,
    { driverId: string; data: DriverUpdate }
  >(
    'update-driver',
    ({ driverId, data }) => mobilityRepository.updateDriver(facilityId, driverId, data!),
    {
      onSuccess: () => {
        toast.success(t('mobility.msgDriverUpdated'));

        setShowDriverForm(false);
        setSelectedDriver(null);
        if (selectedTab === tabs[0]) getDrivers();
        if (selectedTab === tabs[1]) getSharedDrivers();
      },
      onError: (error) => {
        if (error.message) {
          toast.error(t('common.errorMsgDefault'));
        }
      },
    },
  );

  const onDriverStatusChange = (driver: DriverType): void => {
    if (driver?.currentUnavailability) {
      deleteUnavailabilities({ id: driver.id, unavailabilityId: driver.currentUnavailability.id });
      setLoadingProcess(true);
    } else {
      setDriverUnavailable(driver);
    }
  };

  useEffect(() => {
    if (selectedDriver) setShowDriverForm(true);
  }, [selectedDriver]);

  return (
    <section className="hbh-container driver-list">
      <PageTitle
        bottomLine
        className="driver-list-title"
        filters={
          <div>
            {flags.addDriverAndVehicle?.is_active && (
              <Button
                className="btn btn-add"
                text={t('mobility.addDriver')}
                variant="primary"
                onClick={() => {
                  setShowDriverForm(true);
                }}
              />
            )}
          </div>
        }
        title={
          city && country
            ? `${t('mobility.driversListTitle')} ${city}, ${country} | ${agencyName} View`
            : ''
        }
      />

      <Tabs
        badges={[driverTotalItems, sharedDriverTotalItems]}
        className="driver-list-tabs"
        selectedTab={tabs.indexOf(selectedTab)}
        tabs={tabs}
        onTabSelect={setSelectedTab}
      />

      {selectedTab === tabs[0] ? (
        <div className="row">
          <Loader fullScreen spinning={isDriversLoading}>
            {!isDriversLoading && !driverTotalItems ? (
              <div className="empty-list">{t('common.emptyList')} ...</div>
            ) : (
              <>
                {driverList.map((driver: DriverType) => (
                  <Driver
                    driver={driver}
                    isEditable
                    isLoading={isLoadingProcess}
                    key={driver.id}
                    onDriverEdit={setSelectedDriver}
                    onDriverStatusChange={onDriverStatusChange}
                  />
                ))}

                <div className="driver-list-footer">
                  {!isDriversLoading && (
                    <div className="last-update">
                      {t('common.lastUpdate')}: {format(new Date(), 'dd/MM/yyyy HH:mm')}
                    </div>
                  )}

                  {driverTotalItems > 10 && (
                    <Pagination
                      className="pagination"
                      selectedPage={driverSelectedPage}
                      showJumper
                      showPageSize
                      totalPages={driverTotalItems}
                      variant="dark"
                      onPageChange={setDriverSelectedPage}
                      onPageSizeChange={setDriverPageSize}
                    />
                  )}
                </div>
              </>
            )}
          </Loader>
        </div>
      ) : null}

      {selectedTab === tabs[1] ? (
        <div className="row">
          <Loader fullScreen spinning={isSharedDriversLoading}>
            {!isSharedDriversLoading && !sharedDriverTotalItems ? (
              <div className="empty-list">{t('common.emptyList')} ...</div>
            ) : (
              <>
                {sharedDriverList.map((driver: DriverType) => (
                  <Driver driver={driver} key={driver.id} onDriverEdit={setSelectedDriver} />
                ))}

                <div className="driver-list-footer">
                  {!isSharedDriversLoading && (
                    <div className="last-update">
                      {t('common.lastUpdate')}: {format(new Date(), 'dd/MM/yyyy HH:mm')}
                    </div>
                  )}

                  {sharedDriverTotalItems > 10 && (
                    <Pagination
                      className="pagination"
                      selectedPage={sharedDriverSelectedPage}
                      showJumper
                      showPageSize
                      totalPages={sharedDriverTotalItems}
                      variant="dark"
                      onPageChange={setSharedDriverSelectedPage}
                      onPageSizeChange={setSharedDriverPageSize}
                    />
                  )}
                </div>
              </>
            )}
          </Loader>
        </div>
      ) : null}

      {driverUnavailable && (
        <Modal
          showBtnClose
          variant="dark"
          visible={!!driverUnavailable}
          onClose={() => setDriverUnavailable(null)}
        >
          <UnavailabilityForm
            id={driverUnavailable.id}
            label={`${driverUnavailable?.name} ${driverUnavailable?.lastName}`}
            type="driver"
            onSubmit={() => {
              if (selectedTab === tabs[0]) getDrivers();
              if (selectedTab === tabs[1]) getSharedDrivers();
              setDriverUnavailable(null);
            }}
          />
        </Modal>
      )}

      <Modal
        showBtnClose
        variant="light"
        visible={showDriverForm}
        onClose={() => {
          setShowDriverForm(false);
          if (selectedDriver) setSelectedDriver(null);
        }}
      >
        <DriverEditForm
          driverId={selectedDriver}
          isLoading={isDriverCreating || isDriverUpdating}
          readOnly={selectedTab === tabs[1]}
          createDriver={createDriver}
          updateDriver={updateDriver}
        />
      </Modal>
    </section>
  );
};

export default DriversListPage;
