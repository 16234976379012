import { t } from 'i18next';
import { TextField } from '@ui-modules/informed';
import { notEmptyValidator } from '@common/utils';

const VehiclePlateNumberField = ({ disabled }: { disabled?: boolean }) => (
  <div className="field vehicle-plate-number">
    <TextField
      disabled={disabled}
      label={t('mobility.plateNumber')}
      max="10"
      name="plateNumber"
      validate={notEmptyValidator}
    />
  </div>
);

export default VehiclePlateNumberField;
