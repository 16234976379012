/* istanbul ignore file */
import { useEffect } from 'react';
import { useFormApi } from 'informed';
import { t } from 'i18next';
import { IDropdownOption } from '@ui-modules/types';
import { Dropdown, TextField } from '@ui-modules/informed';
import { DriveFormSteps } from '@common/interfaces';
import { notEmptyValidator } from '@common/utils';
import '../styles/modal.scss';

interface FieldProps {
  fieldGroup?: string;
  options?: IDropdownOption[];
  returnTrip?: boolean;
}

const DropOffTownField = ({ fieldGroup, options, returnTrip = false }: FieldProps) => {
  const { getFormState, getValue, setValue } = useFormApi();
  const formState = getFormState().values as any;
  const drives = formState?.[DriveFormSteps.Trip]?.driveList;
  const prevPickupTown = drives ? drives[0]?.pickupTown : null;
  const dropoffLocation = fieldGroup ? `${fieldGroup}.dropoffLocation` : 'dropoffLocation';
  const pickupLocation = fieldGroup ? `${fieldGroup}.pickupLocation` : 'pickupLocation';
  const pickupTown = fieldGroup ? `${fieldGroup}.pickupTown` : 'pickupTown';

  useEffect(() => {
    if (returnTrip && prevPickupTown) {
      setValue(fieldGroup ? `${fieldGroup}.dropoffTown` : 'dropoffTown', prevPickupTown);
    }
  }, [fieldGroup, prevPickupTown, returnTrip, setValue]);

  return (
    <div className="field" data-testid="planner-drive-form-field-dropoff-town">
      {options ? (
        <Dropdown
          className="dropdown"
          isClearable
          isCreatable
          label={t('bookingDetails.dropoffTown')}
          name="dropoffTown"
          options={options}
          validate={notEmptyValidator}
          onChange={(option) => {
            const pickupTownValue = getValue(pickupTown) as IDropdownOption;
            setValue(dropoffLocation, undefined);
            if (
              !pickupTownValue &&
              options?.length === 2 &&
              options?.find((i: IDropdownOption) => i.value === option.value)
            ) {
              setValue(pickupLocation, undefined);
              setValue(
                pickupTown,
                options?.find((i: IDropdownOption) => i.value !== option.value),
              );
            }
          }}
        />
      ) : (
        <TextField
          className="input"
          data-testid="planner-drive-form-field-dropoff-town"
          label={t('bookingDetails.dropoffTown')}
          name="dropoffTown"
          validate={notEmptyValidator}
        />
      )}
    </div>
  );
};

export default DropOffTownField;
