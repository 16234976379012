/* eslint-disable no-nested-ternary */
import { type FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { format, parse } from 'date-fns';
import fileDownload from 'js-file-download';

import { Button } from '@ui-modules';
import { useRepository } from '@context';
import { DateFormat } from '@common/types';
import { useFacility } from '@common/hooks';
import { getErrors } from '@common/utils';
import { AgencyInvoiceApi, AgencyInvoiceDriveApi, AgencyInvoicePatch } from '@common/interfaces';
import {
  Comments as CommentsIcon,
  Letter,
  Services,
  TripsDetails as TripsDetailsIcon,
  Success,
  PDF,
} from '@assets/svg/icons';
import { Modal, Balance, Comments, SendCopy, FormPanel, Loader } from '@components';
import { InvoiceCharges, TripsDetails } from './components';
import './styles.scss';

const CreateAgencyInvoicePage: FC = () => {
  const {
    agencyName,
    facility: { city, country },
    facilityId,
  } = useFacility();
  const { financialRepository } = useRepository();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const invoiceId = useParams<{ id: string }>().id || '';

  const [invoiceData, setInvoiceData] = useState<AgencyInvoiceApi>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [successCreating, setSuccessCreating] = useState<boolean>(false);

  const { isFetching } = useQuery(
    ['get-agency-invoice', selectedPage],
    () =>
      financialRepository.getAgencyInvoiceData(facilityId, invoiceId, {
        limit: 10,
        offset: (selectedPage - 1) * 10,
      }),
    {
      onSuccess(data) {
        setInvoiceData(data);
      },
      onError(err: any) {
        if (err.response) toast.error(getErrors(err.response.data));
      },
    },
  );

  const { mutateAsync: updateInvoice, isLoading: isUpdateInvoiceLoading } = useMutation<
    unknown,
    unknown,
    { data: AgencyInvoicePatch; isSubmitting?: boolean }
  >(
    'update-agency-invoice',
    ({ data }) => financialRepository.updateAgencyInvoice(facilityId, invoiceId, data),
    {
      onSuccess(data: any, { data: patchData, isSubmitting }) {
        if (data) {
          setInvoiceData((prev: any) => ({
            ...prev,
            ...patchData,
            invoice_total: data.invoice_total.toFixed(2),
          }));

          if (isSubmitting) {
            setSuccessCreating(true);
          } else {
            toast.success(t('invoice.msgInvoiceUpdated'));
          }
        }
      },
      onError(err: any) {
        if (err.response) toast.error(getErrors(err.response.data));
        setModalOpen(false);
      },
    },
  );

  const { mutate: deleteDrives } = useMutation<
    { drives: string[]; invoice_total: number },
    unknown,
    { drives: string[] }
  >(
    'delete-agency-invoice',
    ({ drives }) => financialRepository.deleteAgencyInvoiceData(facilityId, invoiceId, { drives }),
    {
      onSuccess(data, payload) {
        setInvoiceData((prev: any) => ({
          ...prev,
          drives: {
            ...prev.drives,
            results: prev.drives.results.filter(
              (i: AgencyInvoiceDriveApi) => !payload?.drives?.includes(i.id),
            ),
          },
          invoice_total: data.invoice_total,
        }));
        toast.success(t('invoice.msgDriveDeleted'));
      },
      onError(err: any) {
        if (err.response) {
          toast.error(getErrors(err.response.data));
        }
      },
    },
  );

  const { refetch: getAgencyPreview } = useQuery(
    'get-agency-invoice-preview',
    () => financialRepository.getAgencyInvoicePreview(facilityId, invoiceId),
    {
      enabled: false,
      onSuccess(data) {
        if (data) {
          const filename = `${agencyName?.replace(/ /g, '_')}_${country}_${city}_${format(
            parse(invoiceData?.reference_date_dmy!, DateFormat.ApiDateAlt, new Date()),
            'MM_yyyy',
          )}.pdf`;

          fileDownload(new File([data], filename), filename);
          navigate(-1);
        }
      },
      onError(err: any) {
        if (err.response) toast.error(getErrors(err.response.data));
        setModalOpen(false);
      },
    },
  );

  if (isFetching) return <Loader fullScreen spinning />;

  return (
    <>
      <section className="agency-invoice-page">
        <div className="header">
          <h1 className="title">
            {t('invoice.pageTitle')}: {city} | {agencyName}
          </h1>
        </div>

        <div className="row">
          <FormPanel
            className="form-panel panel-trips-details"
            header={
              <div>
                <div>
                  {invoiceData?.agency_short_name && (
                    <div>
                      <small>{t('invoice.invoiceFor')}</small>
                      <h3>{invoiceData?.agency_short_name}</h3>
                    </div>
                  )}
                </div>
                <Button
                  text={t('common.btnChange')}
                  variant="transparent"
                  onClick={() => navigate(-1)}
                />
              </div>
            }
            title={
              <>
                <TripsDetailsIcon /> {t('invoice.tripsDetails')}
              </>
            }
          >
            <TripsDetails
              data={invoiceData}
              deleteTrip={(id) => deleteDrives({ drives: [id] })}
              selectedPage={selectedPage}
              onPageChange={setSelectedPage}
            />
          </FormPanel>
        </div>

        <div className="row">
          <FormPanel
            className="form-panel"
            title={
              <>
                <Services /> {t('invoice.charges')}
              </>
            }
          >
            {invoiceData && (
              <InvoiceCharges
                data={invoiceData.services}
                updateInvoice={(data) => updateInvoice({ data })}
              />
            )}
          </FormPanel>
        </div>

        <div className="balance">
          {invoiceData?.total_green_fund ? (
            <Balance
              label={t('common.greenFund')}
              value={invoiceData?.total_green_fund.toString()}
            />
          ) : null}
          <Balance
            label={t('invoice.invoiceTotal')}
            value={invoiceData ? String(invoiceData.invoice_total) : '0'}
            wide
          />
        </div>

        <div className="row">
          <FormPanel
            className="col-left comments"
            title={
              <>
                <CommentsIcon /> {t('invoice.comments')}
              </>
            }
          >
            <Comments
              value={invoiceData?.invoice_comment_text}
              updateInvoice={(data) => updateInvoice({ data })}
            />
          </FormPanel>

          <FormPanel
            className="col-right send-copy"
            title={
              <>
                <Letter /> {t('invoice.sendCopy')}
              </>
            }
          >
            <SendCopy
              defaultValue={invoiceData?.recipients}
              updateInvoice={(data) => updateInvoice({ data })}
            />
          </FormPanel>
        </div>

        <section className="row submit-row">
          <Button
            text={t('invoice.createInvoice')}
            variant="submit"
            onClick={() => setModalOpen(true)}
          />
        </section>
      </section>

      <Modal variant="default" visible={modalOpen}>
        <div className="agency-invoice-modal">
          {isUpdateInvoiceLoading ? (
            <Loader spinning />
          ) : successCreating ? (
            <>
              <Success className="icon" />
              <p className="greeting">{t('invoice.invoiceCreated')}</p>
              <Button
                className="button"
                icon={<PDF />}
                text={t('invoice.viewInvoice')}
                variant="primary"
                onClick={getAgencyPreview}
              />
            </>
          ) : (
            <>
              <p className="modal-greeting">{t('invoice.invoiceCreatedModalGreeting')}</p>
              <p className="modal-title">
                for {city} | for agency {invoiceData?.agency_short_name}
              </p>
              <p className="modal-sub-text">
                {t('invoice.totalAmount')}: ${invoiceData?.invoice_total}
              </p>
              <div className="buttons">
                <Button
                  text={t('common.btnCancel')}
                  variant="danger"
                  onClick={() => setModalOpen(false)}
                />
                <Button
                  text={t('common.btnConfirm')}
                  variant="submit"
                  onClick={() => {
                    updateInvoice({ data: { state: 'submitted' }, isSubmitting: true });
                  }}
                />
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default CreateAgencyInvoicePage;
