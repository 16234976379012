import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '@ui-modules/informed';
import { IDropdownOption } from '@ui-modules/types';
import { notEmptyValidator } from '@common/utils';

interface DriverBloodGroupProps {
  disabled?: boolean;
  options: IDropdownOption[];
}

const DriverBloodGroupField: FC<DriverBloodGroupProps> = ({ disabled, options }) => {
  const { t } = useTranslation();

  return (
    <div className="field field-blood-group">
      <Dropdown
        isDisabled={disabled}
        label={t('mobility.bloodGroup')}
        name="bloodGroup"
        options={options}
        validate={notEmptyValidator}
      />
    </div>
  );
};

export default DriverBloodGroupField;
