/* istanbul ignore file */
import { type FC, useState, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { parse } from 'date-fns';
import type { AxiosError } from 'axios';
import { Form, type FormState, Multistep } from 'informed';
import { Modal } from '@mantine/core';
import cn from 'classnames';

import type { IDropdownOption } from '@ui-modules/types';
import { Loader } from '@components';
import { DateFormat as DF } from '@common/types';
import { useRepository } from '@context';
import { useFacility } from '@common/hooks';
import {
  Drive,
  DriveApi,
  DriveCreate,
  DriveFormDataApi,
  DriveStatus,
  TripType,
  DriveFormState,
  DriveSchemaApi,
  DriveUpdateApi,
  PlDrive,
  PlDriveFormData,
  PlDriver,
  PlVehicle,
  TransferType,
} from '@common/interfaces';
import {
  calcSharedDrive,
  checkConflictDrives,
  checkOverlappingDrives,
  checkShuttleConflictRange,
  checkShuttleType,
  formatDateString,
  getErrors,
  getFullDate,
  getRecurringDriveErrorsText,
  Serializer,
} from '@common/utils';
import {
  ConfirmStep,
  MainStep,
  RecurCreate,
  ReturnTripStep,
  SharedDriveStep,
  TimeSlotEdit,
  TripStep,
} from './components';
import {
  findOption,
  getInitDriveData,
  getInitPaxData,
  mapDriverOptions,
  mapLocationOptions,
  mapPaxOptions,
  mapVehicleOptions,
  tripTypeOpt,
} from '../../utils';
import '../../styles/modal.scss';

let driveCreateData: DriveFormDataApi;

interface DriveModalProps {
  drivers: PlDriver[];
  drives: PlDrive[];
  formData: PlDriveFormData | null;
  showGoogleLocation: boolean;
  vehicles: PlVehicle[];
  onCancel: () => void;
  onSubmit: () => void;
}

const DriveModal: FC<DriveModalProps> = ({
  drivers,
  drives,
  formData,
  showGoogleLocation,
  vehicles,
  onCancel,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { plannerRepo } = useRepository();
  const { facility, facilityId } = useFacility();
  const { agencyId, city } = facility;

  const [drive, setDrive] = useState<Drive>();
  const [isTimeSlotEdit, setTimeSlotEdit] = useState<boolean>(false);
  const [purposeOpt, setPurposes] = useState<IDropdownOption[]>([]);
  const [createRecurringErrors, setCreateRecurringErrors] = useState<string[]>([]);
  const [showRecurringForceCreate, setRecurringForceCreate] = useState<boolean>(false);
  const [stepReturnTripEnabled, setStepReturnTripEnabled] = useState<boolean>(false);

  const { driveStatus, id: driveId, scheduled, vehicleId } = formData ?? {};
  const isEditProcess = !!driveId;
  const isNotEditable = isEditProcess && driveStatus !== DriveStatus.NotStarted;

  const shuttleStops = scheduled?.shuttleConfig?.stops || formData?.stops;
  const driverOpt = mapDriverOptions(drivers);
  const paxOpt = mapPaxOptions(vehicles, vehicleId!);
  const vehicleOpt = mapVehicleOptions(vehicles);
  const locOpt = mapLocationOptions(shuttleStops?.map((i) => i.location));

  const {
    booking,
    driver,
    dropoffDate,
    dropoffLocation,
    dropoffTime,
    dropoffTown,
    hasPassengers,
    id,
    pickupDate,
    pickupLocation,
    pickupTime,
    pickupTown,
    vehicle,
  } = drive || {};

  const {
    agencyFk,
    agencyShortName,
    email,
    firstName,
    indexNumber,
    lastName,
    managerEmail,
    pax,
    passengersInfo,
    phoneNumber,
    purpose,
    refCode,
    remarks,
    requestingUnit,
    state,
    transferType,
    typeOfTrip,
  } = booking || {};

  useQuery('get-drive', () => plannerRepo.getDrive(facilityId, driveId!, { agencyId }), {
    enabled: !!driveId,
    onSuccess: (data: DriveApi) => {
      if (data) setDrive(Serializer.formatDriveToFormData(data));
    },
  });

  useQuery('get-drive-schema', () => plannerRepo.getDriveSchema(facilityId), {
    onSuccess: (data: DriveSchemaApi) => {
      const purposeChoices = data?.booking_extra?.children?.purpose?.choices || [];
      if (purposeChoices.length) setPurposes(purposeChoices.map(Serializer.formatChoices));
    },
  });

  const { mutate: createDrive, isLoading: isDriveCreating } = useMutation<
    unknown,
    AxiosError,
    DriveFormDataApi
  >(
    'planner-drive-create',
    (data) => {
      driveCreateData = data;
      return plannerRepo.createDrive(facilityId, data, { agencyId });
    },
    {
      onSuccess: () => {
        toast.success(t('mobility.msgDrivePublished'));
        onSubmit();
      },
      onError: (e: any) => {
        if (e.response && e.response.data?.errors?.length && driveCreateData?.recurring_option) {
          setRecurringForceCreate(true);
          setCreateRecurringErrors(getRecurringDriveErrorsText(e));
        } else {
          toast.error(getErrors(e.response.data));
        }
      },
    },
  );

  const { mutate: updateDrive, isLoading: isDriveUpdating } = useMutation<
    unknown,
    AxiosError,
    { bookingId: string; data: DriveUpdateApi }
  >(
    'planner-drive-update',
    ({ bookingId, data }) => plannerRepo.updatePlannerDrive(facilityId, bookingId, data),
    {
      onSuccess: () => {
        toast.success(t('mobility.msgDriverUpdated'));
        onSubmit();
      },
      onError: (e: any) => {
        toast.error(getErrors(e.response.data));
      },
    },
  );

  const { isLoading: isDriveCanceling, mutateAsync: cancelDrive } = useMutation(
    'cancel-drive',
    () =>
      plannerRepo.updateDrive(facilityId, booking?.id!, [
        {
          driver,
          dropoff_date: formatDateString(dropoffDate!, 'dd/MM/yyyy', 'yyyy-MM-dd'),
          dropoff_location: dropoffLocation,
          dropoff_time: dropoffTime,
          dropoff_town: dropoffTown,
          is_active: false,
          pickup_date: formatDateString(pickupDate!, 'dd/MM/yyyy', 'yyyy-MM-dd'),
          pickup_location: pickupLocation,
          pickup_time: pickupTime,
          pickup_town: pickupTown,
          uuid: id!,
          vehicle,
        },
      ]),
    {
      onSuccess: () => {
        onSubmit?.();
        toast.success(t('mobility.msgDriveCancel'));
      },
      onError: (error: AxiosError) => {
        if (error.message) toast.error(t('common.errorMsgDefault'));
      },
    },
  );

  const initPaxData: Record<string, unknown>[] = getInitPaxData(paxOpt.length);
  const initDriveData: Record<string, unknown> = getInitDriveData({
    city,
    drive,
    formData,
    isEditProcess,
    options: { driverOpt, locOpt, vehicleOpt },
    showGoogleLocation,
  });

  const initFormData: Record<string, unknown> = {
    mainInfo: isEditProcess
      ? {
          agency: { label: agencyShortName, value: agencyFk },
          email,
          firstName,
          indexNumber,
          lastName,
          managerEmail,
          noPax: !hasPassengers,
          pax: paxOpt.find((i) => +i.value === pax),
          passengersInfo: passengersInfo?.length ? passengersInfo : initPaxData,
          phoneNumber,
          remarks,
        }
      : { passengersInfo: initPaxData },
    returnTrip: {
      driveList: [initDriveData],
    },
    tripInfo: {
      driveList: [initDriveData],
      driveListMultileg: isEditProcess ? [initDriveData] : [initDriveData, initDriveData],
      purpose: findOption(purposeOpt, purpose),
      transferType:
        isEditProcess || scheduled ? scheduled?.bookingTransferType || transferType : '',
      typeOfTrip: findOption(tripTypeOpt, typeOfTrip),
    },
  };

  const handleSubmit = (formState: FormState) => {
    const data = { ...(formState.values as unknown as DriveFormState) };
    const { addRecurring, returnTrip, tripInfo } = data;
    const driveData =
      tripInfo.typeOfTrip?.value !== TripType.MultiLeg
        ? tripInfo?.driveList?.[0]
        : tripInfo?.driveListMultileg?.[0];
    const verifiedType = checkShuttleType({
      drives: drives.filter((i: PlDrive) => i.id !== driveId),
      end: getFullDate(driveData?.dropoffDate!, driveData?.dropoffTime!),
      start: getFullDate(driveData?.pickupDate!, driveData?.pickupTime!),
      transferType: tripInfo.transferType,
      vehicle: driveData?.vehicle?.value!,
    });
    const verifiedTransfer = verifiedType ? verifiedType.transferType : null;
    const routeId =
      verifiedTransfer === TransferType.Shuttle ||
      scheduled?.bookingTransferType === TransferType.Shuttle
        ? verifiedType?.scheduledRouteId || scheduled?.scheduledRouteId
        : null;

    if (isEditProcess) {
      const {
        mainInfo: { requestingUnit: unit, ...rest },
        tripInfo: tripData,
      } = formState.modified as unknown as DriveFormState;
      const modifiedFields = {
        ...(unit.value === requestingUnit ? { ...rest } : { unit, ...rest }),
        ...tripData,
      };

      if (!Object.keys(modifiedFields).length) {
        return toast.error(t('planner.msgNoChanges'));
      }
    }

    if (
      !isEditProcess &&
      tripInfo.typeOfTrip?.value === TripType.MultiLeg &&
      tripInfo.driveListMultileg?.length &&
      checkOverlappingDrives(tripInfo.driveListMultileg)
    ) {
      return toast.error(t('planner.msgOverlappingDrives'));
    }

    if (!addRecurring) {
      if (tripInfo.typeOfTrip?.value === TripType.MultiLeg) {
        const sharedDrive = tripInfo.driveListMultileg?.map((d: DriveCreate) =>
          calcSharedDrive({
            driver: d.driver,
            drives: drives.filter((i: PlDrive) => i.id !== id),
            dropoffDate: d.dropoffDate,
            dropoffTime: d.dropoffTime,
            pickupDate: d.pickupDate,
            pickupTime: d.pickupTime,
            vehicle: d.vehicle,
          }),
        );

        if (sharedDrive?.some((d: PlDrive | null) => d?.id)) {
          return toast.error(t('planner.msgSharedDrive'));
        }
      } else {
        const driveList = [...(tripInfo.driveList || [])];

        if (
          !isEditProcess &&
          tripInfo.typeOfTrip?.value === TripType.RoundTrip &&
          returnTrip?.driveList
        ) {
          driveList.push(...(returnTrip?.driveList || []));
        }

        const conflictDrives = driveList?.map((d: DriveCreate) =>
          checkConflictDrives({
            driver: d.driver,
            drives: drives.filter((i: PlDrive) => i.id !== id),
            dropoffDate: d.dropoffDate,
            dropoffTime: d.dropoffTime,
            pickupDate: d.pickupDate,
            pickupTime: d.pickupTime,
            vehicle: d.vehicle,
          }),
        );

        const [conflictDrivesType] = conflictDrives;

        if (conflictDrivesType) {
          const errorMsg =
            conflictDrivesType === 'driver'
              ? t('planner.msgConflictDrives')
              : `${t('bookingDetails.selected')} ${conflictDrivesType} ${t(
                  'bookingDetails.msgConflictMulti',
                )}`;

          return toast.error(errorMsg);
        }

        if (tripInfo.transferType === TransferType.Shuttle) {
          if (isEditProcess && shuttleStops) {
            const pickupLoc = (driveList[0].pickupLocation as IDropdownOption)?.value;
            const dropoffLoc = (driveList[0].dropoffLocation as IDropdownOption)?.value;

            const isShuttleLocation =
              shuttleStops.some((i) => i.location === pickupLoc) &&
              shuttleStops.some((i) => i.location === dropoffLoc);

            if (isShuttleLocation && shuttleStops?.length) {
              const checkTimeConflict = driveList.some((d: DriveCreate) => {
                const start = parse(shuttleStops[0].pickupTime, DF.ApiTime, new Date());
                const end = parse(
                  shuttleStops[shuttleStops.length - 1].dropoffTime,
                  DF.ApiTime,
                  new Date(),
                );

                return (
                  (d.pickupTime >= start && d.pickupTime <= end) ||
                  (d.dropoffTime >= start && d.dropoffTime <= end)
                );
              });

              if (checkTimeConflict) return toast.error(t('planner.msgConflictNotInShuttle'));
            }
          }

          const checkShuttleConflict = driveList?.map((d: DriveCreate) =>
            checkShuttleConflictRange({
              driver: d.driver.value,
              drives: drives.filter((i: PlDrive) => i.id !== id),
              end: getFullDate(d.dropoffDate, d.dropoffTime),
              start: getFullDate(d.pickupDate, d.pickupTime),
              vehicle: d.vehicle.value,
            }),
          );

          const [conflictType] = checkShuttleConflict;

          if (conflictType === 'driver') return toast.error(t('planner.warnShuttleDriverConflict'));
          if (conflictType === 'time') return toast.error(t('planner.warnShuttleConflict'));
        }
      }
    }

    if (isEditProcess && id) {
      const payload = Serializer.mapPlannerDriveToUpdate({
        data,
        id,
        routeId,
        verifiedTransfer,
      });
      updateDrive({ bookingId: id, data: payload });
    } else {
      const payload = Serializer.mapPlannerDriveForm(data, routeId, verifiedTransfer);
      createDrive(payload);
    }
  };

  const modalTitle = useMemo(
    () => (
      <>
        {driveId ? t('common.editDrive') : t('planner.createDrive')}
        {refCode && <div className="booking-ref-code">Ref code: {refCode}</div>}
      </>
    ),
    [driveId, refCode, t],
  );

  const isLoading = isEditProcess
    ? !drive || !purposeOpt?.length || isDriveUpdating || isDriveCanceling
    : isDriveCreating;

  return (
    <Modal
      classNames={{
        body: 'mp-modal-body',
        close: 'mp-modal-btn-close',
        content: 'mp-modal',
        header: 'mp-modal-header',
        title: 'mp-modal-title',
      }}
      centered
      data-testid="mobility-planner-drive-form"
      opened={!!formData}
      size={580}
      title={modalTitle}
      variant="default"
      onClose={onCancel}
    >
      {isLoading && <Loader spinning />}
      {!isLoading && !isTimeSlotEdit && !showRecurringForceCreate && (
        <Form
          className={cn(`mp-form`, { 'form-disabled': isNotEditable })}
          initialValues={initFormData}
          onSubmit={handleSubmit}
        >
          {isNotEditable && (
            <div className="mp-form-not-editable">{t('planner.msgDriveStatus')}</div>
          )}

          <Multistep>
            {(): JSX.Element => (
              <>
                <MainStep
                  bookingState={state!}
                  drive={drive}
                  formData={formData}
                  paxOptions={paxOpt}
                  onCancel={onCancel}
                  onCancelDrive={cancelDrive}
                  onTimeSlotEdit={() => setTimeSlotEdit(true)}
                />

                <TripStep
                  drive={drive}
                  drives={drives}
                  formData={formData}
                  initMultileg={initDriveData}
                  options={{ driverOpt, locOpt, purposeOpt, tripTypeOpt, vehicleOpt }}
                  showGoogleLocation={showGoogleLocation}
                  shuttleStops={shuttleStops}
                  vehicles={vehicles}
                  setStepReturnTripEnabled={setStepReturnTripEnabled}
                />

                {stepReturnTripEnabled ? (
                  <ReturnTripStep
                    drive={drive}
                    drives={drives}
                    formData={formData}
                    options={{ driverOpt, purposeOpt, tripTypeOpt, vehicleOpt }}
                    showGoogleLocation={showGoogleLocation}
                    vehicles={vehicles}
                  />
                ) : (
                  <>
                    <SharedDriveStep />
                    <ConfirmStep drive={drive} drivers={driverOpt} vehicles={vehicleOpt} />
                  </>
                )}
              </>
            )}
          </Multistep>
        </Form>
      )}
      {!isLoading && isTimeSlotEdit && <TimeSlotEdit data={drive!} onSubmit={onSubmit} />}
      {!isLoading && showRecurringForceCreate && (
        <RecurCreate
          errors={createRecurringErrors}
          onCancel={onCancel}
          onConfirm={async () => {
            createDrive({ ...driveCreateData, force_create: true });
            toast.warning(`${t('common.recurring.successMsg')}.\n ${createRecurringErrors}`);
          }}
        />
      )}
    </Modal>
  );
};

export default DriveModal;
