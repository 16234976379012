/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, FormApi } from 'informed';
import { v4 as uuid } from 'uuid';
import { Table, Button } from '@ui-modules';
import { Dropdown, TextField } from '@ui-modules/informed';
import { IDropdownOption } from '@ui-modules/types';
import { ICharges, ICurrency, IPaymentMethod } from '@common/interfaces';
import { CloseRound, Plus } from '@assets/svg/icons';
import { processFormattedNumber, formatNumber, notEmptyValidator } from '@common/utils';
import DeleteBlock from '../../../../components/invoices/DeleteBlock';
import './styles.scss';

interface IFormCharges {
  quantity: string;
  description: string;
  unitPrice: string;
}

interface IInvoiceCharges {
  currencies: ICurrency[];
  paymentMethods: IPaymentMethod[];
  storedCharges: ICharges[];
  onChangeCurrency: (value: number) => void;
  onChangePayment: (value: string) => void;
  updateInvoice: (data: ICharges[]) => void;
}

const InvoiceCharges: FC<IInvoiceCharges> = ({
  currencies,
  paymentMethods,
  storedCharges,
  onChangeCurrency,
  onChangePayment,
  updateInvoice,
}) => {
  const { t } = useTranslation();

  const [forms, setForms] = useState<string[]>([]);
  const optionsFormRef = useRef<FormApi | null>(null);

  const [defaultCurrencySet, setDefaultCurrencySet] = useState<boolean>(false);

  const currencyOptions = currencies.map(
    (currency): IDropdownOption => ({
      value: currency.id.toString(),
      label: currency.iso_code,
    }),
  );

  const paymentOptions = paymentMethods.map(
    (payment): IDropdownOption => ({
      value: payment.id.toString(),
      label: payment.description,
    }),
  );

  const addForm = (): void => {
    setForms((prevForms) => [...prevForms, uuid()]);
  };

  const tableData = storedCharges?.map((props: ICharges, idx: number) => {
    const { description, quantity, unit_price } = props;

    return {
      actions: (
        <DeleteBlock
          key={description}
          id={idx}
          deleteAction={() => {
            const newItems = storedCharges.filter(
              (item) => JSON.stringify(props) !== JSON.stringify(item),
            );
            updateInvoice(newItems);
          }}
        />
      ),
      description: description || '',
      id: idx,
      quantity,
      unit_price,
    };
  });

  const columns = useMemo(
    () => [
      {
        dataIndex: 'description',
        key: 'description',
        title: 'Service',
      },
      {
        dataIndex: 'quantity',
        key: 'quantity',
        title: t('invoice.quantity'),
      },
      {
        dataIndex: 'unit_price',
        key: 'unit_price',
        title: t('common.price'),
      },
      {
        dataIndex: 'actions',
        key: 'actions',
        title: '',
      },
    ],
    [t],
  );

  useEffect(() => {
    if (currencyOptions.length && paymentOptions.length && !defaultCurrencySet) {
      optionsFormRef.current?.setValue('currency', currencyOptions[0]);
      optionsFormRef.current?.setValue('payment', paymentOptions[0]);
      setDefaultCurrencySet(true);
    }
  }, [currencyOptions, defaultCurrencySet, paymentOptions]);

  return (
    <section className="charges-container">
      <Form formApiRef={optionsFormRef} className="options">
        <Dropdown
          className="dropdown"
          name="currency"
          label={t('common.currency')}
          options={currencyOptions}
          defaultValue={currencyOptions[0]}
          onChange={(option) => onChangeCurrency(+option.value)}
        />
        <Dropdown
          className="dropdown"
          name="payment"
          label={t('common.paymentMethod')}
          options={paymentOptions}
          defaultValue={paymentOptions[0]}
          onChange={(option) => onChangePayment(option.value)}
        />
      </Form>

      <div className="table">
        <Table columns={columns} data={tableData} locale={{ emptyText: 'No items' }} />
      </div>

      {forms.map((id: string) => (
        <Form
          className="form"
          key={id}
          onSubmit={(state) => {
            const { description, quantity, unitPrice } = state.values as unknown as IFormCharges;

            if (description && quantity && unitPrice) {
              updateInvoice([
                ...storedCharges,
                {
                  description,
                  quantity: +processFormattedNumber(quantity),
                  unit_price: +processFormattedNumber(unitPrice),
                },
              ]);
              setForms([]);
            }
          }}
        >
          <div className="form-row">
            <div className="form-cell">
              <TextField name="description" placeholder="Service" validate={notEmptyValidator} />
            </div>
            <div className="form-cell">
              <TextField
                name="quantity"
                placeholder={t('invoice.quantity')}
                mask={formatNumber}
                validate={notEmptyValidator}
              />
            </div>
            <div className="form-cell">
              <TextField
                name="unitPrice"
                placeholder={t('common.price')}
                mask={(value) => formatNumber(value, true)}
                validate={notEmptyValidator}
              />
            </div>
            <div className="form-actions">
              <Button
                className="confirm"
                text={t('common.btnConfirm')}
                type="submit"
                variant="primary"
              />
              <Button
                className="delete"
                icon={<CloseRound />}
                variant="icon"
                onClick={() => setForms((prevForms) => [...prevForms].filter((i) => i !== id))}
              />
            </div>
          </div>
        </Form>
      ))}
      <div className="footer">
        <Button icon={<Plus />} text="Add Service" variant="secondary" onClick={() => addForm()} />
      </div>
    </section>
  );
};

export default InvoiceCharges;
