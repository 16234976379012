import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldState } from 'informed';
import { Dropdown } from '@ui-modules/informed';
import { IDropdownOption } from '@ui-modules/types';

interface PreferredVehicleProps {
  disabled?: boolean;
  options: IDropdownOption[];
}

const PreferredVehicleField: FC<PreferredVehicleProps> = ({ disabled, options }) => {
  const { t } = useTranslation();

  const { value: sharedLocation } = useFieldState('sharedLocation');

  const validate = (value: unknown): string | void => {
    if ((sharedLocation as IDropdownOption)?.value.length > 0 && !value) {
      return 'This field is required';
    }
  };

  return (
    <div className="field field-preferred-vehicle">
      <Dropdown
        isDisabled={disabled}
        label={t('mobility.preferredVehicle')}
        name="preferredVehicle"
        options={options}
        validate={validate}
      />
    </div>
  );
};

export default PreferredVehicleField;
