import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IndInvoiceDetails } from '@common/interfaces';
import './styles.scss';

interface IPassengerDetails {
  data?: IndInvoiceDetails;
}

const PassengerDetails: FC<IPassengerDetails> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <ul className="passenger-details">
      <li>
        <div className="label">{t('common.name')}</div>
        <div className="value">{data?.firstName}</div>
      </li>
      <li>
        <div className="label">{t('common.surname')}</div>
        <div className="value">{data?.lastName}</div>
      </li>
      <li>
        <div className="label">{t('common.email')}</div>
        <div className="value">{data?.email}</div>
      </li>
      <li>
        <div className="label">{t('common.phoneNumber')}</div>
        <div className="value">{data?.phoneNumber}</div>
      </li>
      <li>
        <div className="label">{t('common.agency')}</div>
        <div className="value">{data?.agency?.shortName || '-'}</div>
      </li>
      <li>
        <div className="label">{t('common.vendorNumber')}</div>
        <div className="value">{data?.indexNumber || '-'}</div>
      </li>
      <li>
        <div className="label">{t('invoice.requestUnit')}</div>
        <div className="value">{data?.requestingUnitDisplay}</div>
      </li>
      <li>
        <div className="label">{t('common.purpose')}</div>
        <div className="value">{data?.purposeDisplay}</div>
      </li>
    </ul>
  );
};

export default PassengerDetails;
