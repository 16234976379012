/* istanbul ignore file */
import { IDropdownOption } from '@ui-modules/types';
import { FuelType } from './Common';

export enum SummaryType {
  BasedBookingCoords = 0,
  DriverManualInput,
  FocalPointManualInput,
  VTS,
  GPS,
}

type DashboardItemResponse = Record<string, Record<string, string | number>[] | number>;
type DashboardChartDataResponse = { label: string; value: number }[];

export interface DashboardDataApi {
  [x: string]: DashboardChartDataResponse | DashboardItemResponse;
}

export interface DashboardData {
  agencyShortName: DashboardChartDataResponse;
  driveType: DashboardChartDataResponse;
  recurringTypeOfTrip: DashboardChartDataResponse;
  requestingUnit: DashboardChartDataResponse;
  state: DashboardChartDataResponse;
  total: DashboardItemResponse;
  transferType: DashboardChartDataResponse;
  typeOfTrip: DashboardChartDataResponse;
}

type DashboardBarChartItem = { agenda: string[]; data: { data: number[]; label: string }[] };
type DashboardPieChartItem = { data: number[]; labels: string[]; total: number };
export type DashboardFiguresItem = Record<string, string | number>;

export interface Dashboard {
  agencyShortName: DashboardPieChartItem;
  driveType: DashboardPieChartItem;
  driverDrives: DashboardBarChartItem;
  driverMileage: DashboardBarChartItem;
  recurringTypeOfTrip: DashboardPieChartItem;
  requestingUnit: DashboardBarChartItem;
  state: DashboardPieChartItem;
  total: DashboardFiguresItem;
  transferType: DashboardPieChartItem;
  typeOfTrip: DashboardPieChartItem;
  vehicleDrives: DashboardBarChartItem;
  vehicleMileage: DashboardBarChartItem;
}

export interface DashboardDriveApi {
  is_active: boolean;
  label: string;
  total_drives: number;
  total_mileage: string;
  uuid: string;
}

export interface DashboardDrive {
  driverDrives: DashboardBarChartItem;
  driverMileage: DashboardBarChartItem;
  vehicleDrives: DashboardBarChartItem;
  vehicleMileage: DashboardBarChartItem;
}

export type FilterReport = {
  agency?: IDropdownOption;
  customPeriod: [Date, Date];
  location: IDropdownOption;
  period: IDropdownOption;
};

export interface ICountryReportApi {
  agency_pax: number;
  airport_drives: number;
  bookings_count: number;
  carpooled_drives: number;
  carpooled_pax: number;
  co_2_saving: number;
  drivers: number;
  drives: number;
  efficiency_saving: number;
  electric_vehicle_drives: number;
  in_town_drives: number;
  location_name: string;
  out_of_town_drives: number;
  pax: number;
  reference_date: string;
  ride_shared_drives: number;
  ride_shared_pax: number;
  total_drives_km: number;
  total_drives_time: number;
  vehicles: number;
}

export interface ICountryReport {
  agencyPax: number;
  airportDrives: number;
  bookingsCount: number;
  carpooledDrives: number;
  carpooledPax: number;
  co2Saving: number;
  drivers: number;
  drives: number;
  drivesDieselPetrol: number;
  drivesElectric: number;
  efficiencySaving: number;
  inTownDrives: number;
  locationName: string;
  outOfTownDrives: number;
  pax: number;
  referenceDate: string;
  rideSharedDrives: number;
  rideSharedPax: number;
  totalDrivesKm: number;
  totalDrivesTime: number;
  vehicles: number;
}

export interface IDriveWorkflowApi {
  distance: number;
  distance_based_on_mobile_app: number;
  duration: string;
  end_km: number;
  name: string;
  start_km: number;
}

export interface IDriveWorkflow {
  distance: number;
  distanceBasedOnMobileApp: number;
  duration: string;
  endKm: number;
  name: string;
  startKm: number;
}

export interface IDriveWithWorkflowApi {
  distance: number;
  end_date: string;
  end_km: number;
  end_time: string;
  is_ride_shared: boolean;
  shared_drives: ISharedDrivesApi[];
  start_date: string;
  start_km: number;
  start_time: string;
  workflows: IDriveWorkflowApi[];
}

export interface IDriveWithWorkflow {
  distance: number;
  endDate: string;
  endKm: number;
  endTime: string;
  isRideShared: boolean;
  sharedDrives: ISharedDrives[];
  startDate: string;
  startKm: number;
  startTime: string;
  workflows: IDriveWorkflow[];
}

export interface IDriveUpdate {
  distance: number;
  end_date: string;
  end_km?: number;
  end_time: string;
  start_date: string;
  start_km?: number;
  start_time: string;
}

export interface IMonthlySummaryApi {
  airport: number;
  amount: string;
  bookings_count: number;
  drives: number;
  green_fund: string;
  in_town: number;
  out_of_town: number;
  total_mileage: string;
  total_time: string;
}

export interface IMonthlySummary {
  airport: number;
  amount: string;
  bookingsCount: number;
  drives: number;
  greenFund: string;
  inTown: number;
  outOfTown: number;
  totalMileage: string;
  totalTime: string;
}

export interface IReportDetailsListApi {
  agency: string;
  booking_id: string;
  cost_category: string;
  details: IDriveWorkflowApi | null;
  driver_agency: string;
  driver_full_name: string;
  duration: string;
  fuel_type: FuelType;
  has_workflow: boolean;
  is_carpooled: boolean;
  is_editable_by_focal_point: boolean;
  is_manual_edited: boolean;
  is_ride_shared: boolean;
  is_synced: boolean;
  km: number;
  passenger: string;
  pax: string;
  pickup_date: string;
  price: number;
  reference_code: string;
  summary_type: SummaryType;
  summary_type_display: string;
  transfer_type: string;
  type_of_trip: string;
  unit: string;
  uuid: string;
  vehicle: string;
  vts_data: IDriveWorkflowApi | null;
}

export interface IReportDetailsList {
  agency: string;
  bookingId: string;
  costCategory: string;
  details: IDriveWorkflow | null;
  driverAgency: string;
  driverFullName: string;
  duration: string;
  fuelType: FuelType;
  hasWorkflow: boolean;
  id: string;
  isCarpooled: boolean;
  isEditableByFocalPoint: boolean;
  isManualEdited: boolean;
  isRideShared: boolean;
  isSynced: boolean;
  km: number;
  passenger: string;
  pax: string;
  pickupDate: string;
  price: number;
  refCode: string;
  summaryType: SummaryType;
  summaryTypeDisplay: string;
  transferType: string;
  typeOfTrip: string;
  unit: string;
  vehicle: string;
  vtsData: IDriveWorkflow | null;
}

export interface ISharedDrivesApi {
  booking_ref: string;
  duration: string;
  mileage: number;
  vehicle_label: string;
}

export interface ISharedDrives {
  bookingRef: string;
  duration: string;
  mileage: number;
  vehicleLabel: string;
}

export interface ISummaryListApi {
  agency: string;
  agency_id: string;
  airport: number;
  amount: string;
  bookings_count: number;
  carpooled_drives: number;
  carpooled_pax: number;
  drives: number;
  green_fund: string;
  in_town: number;
  out_of_town: number;
  pdf?: string | null;
  ride_shared_drives: number;
  ride_shared_pax: number;
  total_mileage: string;
  total_time: string;
}

export interface ISummaryList {
  agency: string;
  agencyId: string;
  airport: number;
  amount: string;
  bookingsCount: number;
  carpooledDrives: number;
  carpooledPax: number;
  drives: number;
  greenFund: string;
  inTown: number;
  outOfTown: number;
  pdf: string;
  rideSharedDrives: number;
  rideSharedPax: number;
  totalMileage: string;
  totalTime: string;
}
