import type { FC } from 'react';
import { t } from 'i18next';
import { TextField } from '@ui-modules/informed';
import { notEmptyValidator } from '@common/utils';

interface FirstNameProps {
  disabled?: boolean;
  label?: string;
}

const FirstNameField: FC<FirstNameProps> = ({ disabled, label = t('common.firstName') }) => (
  <div className="field field-name">
    <TextField disabled={disabled} label={label} name="name" validate={notEmptyValidator} />
  </div>
);

export default FirstNameField;
