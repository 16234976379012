import type { FC } from 'react';
import { t } from 'i18next';
import { Dropdown } from '@ui-modules/informed';
import { IDropdownOption } from '@ui-modules/types';
import { notEmptyValidator } from '@common/utils';

interface VehicleModelProps {
  disabled?: boolean;
  options: IDropdownOption[];
}

const VehicleModelField: FC<VehicleModelProps> = ({ disabled, options = [] }) => (
  <div className="field vehicle-model">
    <Dropdown
      isDisabled={disabled}
      label={t('mobility.vehicleModel')}
      name="vehicleModel"
      options={options}
      validate={notEmptyValidator}
    />
  </div>
);

export default VehicleModelField;
