import { t } from 'i18next';
import { TextField } from '@ui-modules/informed';

const VehicleChassisNumberField = ({ disabled }: { disabled?: boolean }) => (
  <div className="field vehicle-chassis-number">
    <TextField
      disabled={disabled}
      label={t('mobility.chassisNumber')}
      max="17"
      name="chassisNumber"
    />
  </div>
);

export default VehicleChassisNumberField;
