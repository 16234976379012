/* istanbul ignore file */
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { addMinutes, format, isBefore, isMatch, isSameDay, isSameMinute, parse } from 'date-fns';
import { useFormApi } from 'informed';
import { DateField } from '@ui-modules/informed';
import { TIME_INTERVAL } from '@common/constants';
import { type PlAcceptDrive, TransferType } from '@common/interfaces';
import { useFieldState } from '@common/hooks';
import { DateFormat as DF } from '@common/types';
import { notEmptyValidator } from '@common/utils';
import { Info } from '@assets/svg/icons';
import '../../BookingPanel.styles.scss';
import { Tooltip } from '@components';

type FieldProps = {
  acceptDrives?: PlAcceptDrive[];
  disabled?: boolean;
  isEdit?: boolean;
  leg: number;
  value?: string;
  onChange?: (value: Date | string) => void;
};

const DropOffTimeField = ({ acceptDrives, disabled, isEdit, leg, value, onChange }: FieldProps) => {
  const formApi = useFormApi();
  const { value: dropoffDate } = useFieldState<Date>(`${leg}.dropoffDate`);
  const { value: dropoffTime } = useFieldState<Date>(`${leg}.dropoffTime`);
  const { value: pickupDate } = useFieldState<Date>(`${leg}.pickupDate`);
  const { value: pickupTime } = useFieldState<Date>(`${leg}.pickupTime`);

  const [maxTime, setMaxTime] = useState<Date | undefined>();
  const [minTime, setMinTime] = useState<Date | undefined>();

  const drive = acceptDrives?.[leg - 1];
  const isShuttle = drive?.transferType === TransferType.Shuttle;

  useEffect(() => {
    if (pickupDate) {
      const isSameDate = isSameDay(dropoffDate, pickupDate);

      if (isSameDate) {
        if (
          !drive?.extra?.estimatedDuration &&
          (isBefore(dropoffTime, pickupTime) || isSameMinute(dropoffTime, pickupTime))
        ) {
          formApi.setValue(`${leg}.dropoffTime`, addMinutes(pickupTime, TIME_INTERVAL));
        }

        setMaxTime(parse('23:45', DF.ApiTime, new Date()));
        setMinTime(addMinutes(pickupTime, TIME_INTERVAL));
      } else {
        setMaxTime(undefined);
        setMinTime(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropoffDate, pickupDate, pickupTime]);

  useEffect(() => {
    if (drive && value) {
      const isScheduled = drive?.isScheduled;
      if (isScheduled) formApi.setValue(`${leg}.dropoffTime`, parse(value, DF.ApiTime, new Date()));
    }
  }, [drive, formApi, leg, value]);

  useEffect(() => {
    if (isEdit && value) {
      formApi.setValue(`${leg}.dropoffTime`, parse(value, DF.ApiTime, new Date()));
    }
  }, [formApi, isEdit, leg, value]);

  useEffect(() => {
    if (
      pickupDate &&
      pickupTime &&
      !isShuttle &&
      !drive?.isScheduled &&
      drive?.extra?.estimatedDuration &&
      isMatch(drive?.extra?.estimatedDuration!, DF.ApiTimeFull)
    ) {
      const date = new Date(`${format(pickupDate, DF.ApiDate)}T${format(pickupTime, DF.ApiTime)}`);
      const [hours, minutes] = drive.extra.estimatedDuration.split(':').map(Number);
      const dropoffEstimated = addMinutes(date, hours * 60 + minutes);

      if (dropoffEstimated) {
        formApi.setValue(`${leg}.dropoffDate`, dropoffEstimated);
        formApi.setValue(`${leg}.dropoffTime`, dropoffEstimated);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drive?.extra?.estimatedDuration, pickupDate, pickupTime]);

  return (
    <div className="field" data-testid={`planner-booking-panel-dropoff-time-${leg}`}>
      <DateField
        className="input"
        dateFormat="HH:mm"
        defaultValue={isShuttle && value ? parse(value, DF.ApiTime, new Date()) : undefined}
        disabled={disabled}
        label={
          <Tooltip label={t('bookingDetails.estimatedByGoogle') as string} width={400}>
            <>
              {t('bookingDetails.dropoffTime')}&nbsp;
              {!isEdit && <Info />}
            </>
          </Tooltip>
        }
        maxTime={maxTime}
        minTime={minTime}
        name={`${leg}.dropoffTime`}
        placeholder={t('bookingDetails.chooseTime')}
        showTimeSelect
        showTimeSelectOnly
        timeCaption={undefined}
        timeFormat="HH:mm"
        timeIntervals={TIME_INTERVAL}
        validate={notEmptyValidator}
        onChange={onChange}
      />
    </div>
  );
};

export default DropOffTimeField;
