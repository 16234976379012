/* istanbul ignore file */
/* eslint-disable no-nested-ternary */
import { type FC, useEffect } from 'react';
import { t } from 'i18next';
import { useFormApi } from 'informed';

import { Dropdown } from '@ui-modules/informed';
import type { IDropdownOption } from '@ui-modules/types';
import type { PlDrive, PlVehicle } from '@common/interfaces';
import { useFieldState } from '@common/hooks';
import { notEmptyValidator } from '@common/utils';
import '../styles/modal.scss';

interface IDriverField {
  driver?: string | null;
  fieldGroup?: string;
  isEdit?: boolean;
  isShuttle?: boolean;
  options: IDropdownOption[];
  sharedDrive?: PlDrive | null;
  vehicles?: PlVehicle[];
}

const DriverField: FC<IDriverField> = ({
  driver,
  fieldGroup,
  isEdit,
  isShuttle,
  options,
  sharedDrive,
  vehicles,
}) => {
  const { setValue } = useFormApi();
  const { value: driverOption } = useFieldState<IDropdownOption>('driver');
  const { value: vehicle } = useFieldState<IDropdownOption>('vehicle');

  const selectedVehicle = vehicles?.find((v) => v.id === vehicle?.value);
  const preferredDriver = selectedVehicle?.shared
    ? options.find((d) => d.value === selectedVehicle?.preferredDriver)
    : undefined;

  const defaultValue =
    preferredDriver || options.find((i) => i.value === (sharedDrive?.driverId || driver));

  useEffect(() => {
    if (vehicle) {
      if (driverOption) {
        const isSharedDriverSelected = vehicles?.some(
          (v: PlVehicle) => v.shared && v.preferredDriver === driverOption.value,
        );

        if (isSharedDriverSelected && !selectedVehicle?.shared) {
          setValue(fieldGroup ? `${fieldGroup}.driver` : 'driver', null);
          return;
        }
      }

      if (selectedVehicle?.shared && preferredDriver && isEdit) {
        setValue(fieldGroup ? `${fieldGroup}.driver` : 'driver', preferredDriver);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicle]);

  useEffect(() => {
    if (defaultValue) {
      setValue(fieldGroup ? `${fieldGroup}.driver` : 'driver', defaultValue);
    }
  }, [defaultValue, fieldGroup, setValue]);

  return (
    <div className="field" data-testid="planner-drive-form-field-driver">
      <Dropdown
        className="dropdown"
        defaultValue={defaultValue}
        isDisabled={isShuttle ? false : sharedDrive !== null || preferredDriver !== undefined}
        label={t('common.driver')}
        name="driver"
        options={options}
        validate={notEmptyValidator}
      />
    </div>
  );
};

export default DriverField;
