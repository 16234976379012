import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { Form } from 'informed';

import { Button, Pagination, Table } from '@unbooking/ui-modules';
import { Dropdown, TextField } from '@ui-modules/informed';
import { IDropdownOption } from '@ui-modules/types';

import { useFacility } from '@common/hooks';
import { useRepository } from '@context';
import { Serializer } from '@common/utils';
import {
  IAgencyInvoiceCreate,
  IMonthlySummary,
  ISummaryList,
  ReportParams,
} from '@common/interfaces';
import { CURRENT_MONTH, CURRENT_YEAR, MONTHS, YEARS } from '@common/constants';
import { DateFormat } from '@common/types';
import MonthlySummary from './MonthlySummary';

interface IFormState {
  month: IDropdownOption;
  preparedBy: string;
  year: IDropdownOption;
}

interface ISummary {
  username: string;
  exportDrives: (data: ReportParams) => void;
}

const Summary: FC<ISummary> = ({ username, exportDrives }) => {
  const navigate = useNavigate();
  const {
    facility: { agencyId },
    facilityId,
  } = useFacility();
  const { financialRepository, reportRepository } = useRepository();
  const { t } = useTranslation();

  const [monthlySummary, setMonthlySummary] = useState<IMonthlySummary | null>(null);

  const [filterMonth, setFilterMonth] = useState<string>(CURRENT_MONTH.value);
  const [filterYear, setFilterYear] = useState<string>(CURRENT_YEAR.value);
  const [summaryData, setSummaryData] = useState<ISummaryList[]>([]);
  const [columnOrder, setColumnOrder] = useState<string>('');
  const [pageSize, setPageSize] = useState<number>(10);
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);

  const { data, isLoading: isReportDataLoading } = useQuery(
    ['cost-summary-list', filterMonth, filterYear, columnOrder, pageSize, selectedPage],
    () =>
      reportRepository.getSummary(facilityId, {
        agencyId,
        limit: pageSize,
        month: filterMonth,
        offset: (selectedPage - 1) * 10,
        ordering: columnOrder,
        year: filterYear,
      }),
    { enabled: !!agencyId, refetchOnWindowFocus: false },
  );

  const { data: monthlySummaryData, isLoading: isMonthlySummaryLoading } = useQuery(
    ['cost-monthly-summary', filterMonth, filterYear],
    () =>
      reportRepository.getMonthlySummary(facilityId, {
        agencyId,
        month: filterMonth,
        year: filterYear,
      }),
    { enabled: !!agencyId, refetchOnWindowFocus: false },
  );

  const { mutateAsync: createInvoice } = useMutation<
    { id: string },
    AxiosError,
    { invoiceData: IAgencyInvoiceCreate }
  >(
    'create-agency-invoice',
    ({ invoiceData }) => financialRepository.createAgencyInvoice(facilityId, invoiceData),
    {
      onSuccess: ({ id }) => {
        if (id) {
          navigate(`invoice/${id}`);
        }
      },
      onError: (error) => {
        if (error.message) {
          toast.error(t('common.errorMsgDefault'));
        }
      },
    },
  );

  useEffect(() => {
    if (data) {
      setSummaryData(data.map(Serializer.formatSummary));
      setTotalItems(data.count);
    }

    if (monthlySummaryData) {
      setMonthlySummary(Serializer.formatMonthlySummary(monthlySummaryData));
    } else {
      setMonthlySummary(null);
    }
  }, [data, monthlySummaryData]);

  const columns = [
    {
      dataIndex: 'agency',
      key: 'agency',
      title: t('common.agency'),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      dataIndex: 'drives',
      key: 'drives',
      title: t('common.trips'),
    },
    {
      dataIndex: 'bookingsCount',
      key: 'bookingsCount',
      title: t('crumbs.bookings'),
    },
    {
      dataIndex: 'totalMileage',
      key: 'totalMileage',
      title: t('report.totalMileage'),
    },
    {
      dataIndex: 'totalTime',
      key: 'totalTime',
      title: t('report.totalTime'),
    },
    {
      dataIndex: 'airport',
      key: 'airport',
      title: t('common.airport'),
    },
    {
      dataIndex: 'inTown',
      key: 'inTown',
      title: t('common.inTown'),
    },
    {
      dataIndex: 'outOfTown',
      key: 'outOfTown',
      title: t('common.outOfTown'),
    },
    {
      dataIndex: 'amount',
      key: 'amount',
      title: `${t('common.amount')} $`,
      render: (record: string) => <span>{Number(record).toFixed(2)}</span>,
    },
    {
      dataIndex: 'greenFund',
      key: 'greenFund',
      title: `${t('common.greenFund')} $`,
    },
    {
      dataIndex: 'carpooledDrives',
      key: 'carpooledDrives',
      title: t('report.carpooledTrip'),
    },
    {
      dataIndex: 'rideSharedDrives',
      key: 'rideSharedDrives',
      title: t('report.ridesharedTrip'),
    },
    {
      key: 'actions',
      title: '',
      render: (record: ISummaryList) => (
        <div className="col-actions">
          {record.pdf ? (
            <Button
              className="btn-download"
              link={record.pdf}
              text={t('common.btnDownloadInvoice')}
              variant="submit"
            />
          ) : (
            <Button
              className="btn-invoice"
              text={t('invoice.createInvoice')}
              variant="primary"
              onClick={() => {
                const referenceDate = format(
                  new Date(+filterYear, +filterMonth - 1, 1),
                  DateFormat.ApiDate,
                );

                createInvoice({
                  invoiceData: {
                    agency: record.agencyId,
                    agency_of_fp: agencyId,
                    reference_date: referenceDate,
                  },
                });
              }}
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <section className="summary">
      <section className="summary-list">
        <div className="row">
          <Form
            className="summary-list-form"
            id="report_filters_form"
            onSubmit={(formState: Record<string, unknown>) => {
              const state = { ...(formState.values as IFormState) };
              exportDrives({
                agencyId,
                month: state.month.value,
                preparedBy: state.preparedBy,
                year: state.year.value,
              });
            }}
          >
            <div className="export-fields">
              <div className="field">
                <TextField
                  disabled
                  initialValue={username}
                  label={t('report.inputPreparedBy')}
                  name="preparedBy"
                  placeholder={t('report.inputPreparedBy')}
                />
              </div>
            </div>

            <div className="filters">
              <div className="field month">
                <Dropdown
                  defaultValue={CURRENT_MONTH}
                  label={t('common.month')}
                  name="month"
                  options={MONTHS}
                  onChange={({ value }: IDropdownOption) => {
                    setFilterMonth(value);
                    setSelectedPage(1);
                  }}
                />
              </div>

              <div className="field year">
                <Dropdown
                  defaultValue={CURRENT_YEAR}
                  label={t('common.year')}
                  name="year"
                  options={YEARS}
                  onChange={({ value }: IDropdownOption) => {
                    setFilterYear(value);
                    setSelectedPage(1);
                  }}
                />
              </div>
            </div>
          </Form>
        </div>

        <div className="summary-list-table">
          <Table
            columns={columns}
            data={summaryData}
            isLoading={isReportDataLoading}
            rowKey="agencyId"
            variant="light"
            onChangeColumnOrder={setColumnOrder}
          />
        </div>

        {totalItems > 10 ? (
          <Pagination
            className="pagination"
            selectedPage={selectedPage}
            showJumper
            showPageSize
            totalPages={totalItems}
            variant="light"
            onPageChange={setSelectedPage}
            onPageSizeChange={setPageSize}
          />
        ) : null}
      </section>
      <section className="summary-monthly">
        <MonthlySummary data={monthlySummary} loading={isMonthlySummaryLoading} />
      </section>
    </section>
  );
};

export default Summary;
