import type { FC } from 'react';
import { t } from 'i18next';
import { TextField } from '@ui-modules/informed';

interface VehicleRoofNumberProps {
  disabled?: boolean;
}

const VehicleRoofNumberField: FC<VehicleRoofNumberProps> = ({ disabled }) => (
  <div className="field vehicle-roof-number">
    <TextField disabled={disabled} label={t('mobility.roofNumber')} max="17" name="roofNumber" />
  </div>
);

export default VehicleRoofNumberField;
