import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { Button } from '@ui-modules';
import { useRepository } from '@context';
import { Balance, Comments, FormPanel, Loader, Modal, SendCopy } from '@components';
import { useFacility } from '@common/hooks';
import { DateFormat } from '@common/types';
import { addTimeToTime, getErrors, Serializer } from '@common/utils';
import {
  ApiList,
  ICurrency,
  IndInvoiceDetailsApi,
  IndInvoiceDetails,
  IIndividualInvoicePatch,
  IIndividualInvoiceCreate,
  IPaymentMethod,
} from '@common/interfaces';
import {
  Comments as CommentsIcon,
  Letter as LetterIcon,
  Services as ServicesIcon,
  Success as SuccessIcon,
  TripsDetails as TripsDetailsIcon,
  User as UserIcon,
} from '@assets/svg/icons';
import { InvoiceCharges, PassengerDetails, TripsDetails } from './components';
import './styles.scss';

const DEFAULT_ISO_CODE = 'USD';

const initialIndividualInvoice: IIndividualInvoicePatch = {
  charges: [],
  email: '',
  first_name: '',
  green_fund: 0,
  invoice_comment_text: '',
  last_name: '',
  purpose: '',
  requesting_unit: '',
};

const CreateIndividualInvoicePage: FC = () => {
  const {
    agencyName,
    facility: { city },
    facilityId,
  } = useFacility();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const bookingId = useParams<{ id: string }>()?.id;

  const { financialRepository } = useRepository();

  const [currencies, setCurrencies] = useState<ICurrency[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);
  const [invoiceData, setInvoiceData] = useState<IndInvoiceDetails>();
  const [invoiceTotal, setInvoiceTotal] = useState<number>(0);
  const [greenFundTotal, setGreenFundTotal] = useState<number>(0);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [patchInvoiceData, setPatchInvoiceData] =
    useState<IIndividualInvoicePatch>(initialIndividualInvoice);

  const { isLoading: isCurrencyLoading } = useQuery(
    ['get-currencies'],
    () => financialRepository.getInvoiceCurrencies(facilityId, { iso_code: DEFAULT_ISO_CODE }),
    {
      enabled: !!invoiceData,
      onSuccess(data: ApiList<ICurrency>) {
        setPatchInvoiceData({ ...patchInvoiceData, invoice_currency: data.results[0].id });
        setCurrencies(data.results);
      },
    },
  );

  const { isLoading: isPaymentLoading } = useQuery(
    ['get-payment-methods'],
    () => financialRepository.getInvoicePaymentMethods(facilityId),
    {
      enabled: !!currencies.length,
      onSuccess(data: ApiList<IPaymentMethod>) {
        setPatchInvoiceData({ ...patchInvoiceData, payment_method: data.results[0].id });
        setPaymentMethods(data.results);
      },
    },
  );

  const { isLoading: isInvoiceDataLoading } = useQuery(
    ['get-individual-invoice-data'],
    () => financialRepository.getIndividualInvoiceData(facilityId, bookingId!),
    {
      onSuccess(data: IndInvoiceDetailsApi) {
        if (data) {
          const invoiceDetailsData = Serializer.formatIndividualInvoiceDetails(data);

          let totalDuration = '';
          let totalMileage = 0;
          let totalGreenFund = 0;

          if (invoiceDetailsData?.driveSet.length) {
            let total = 0;

            invoiceDetailsData?.driveSet.forEach((drive) => {
              total += drive.price || 0;

              if (drive.duration) {
                if (!totalDuration.length) {
                  totalDuration = drive.duration;
                } else if (totalDuration.length) {
                  totalDuration = addTimeToTime(totalDuration, drive.duration);
                }

                if (drive.km) {
                  totalMileage += +drive.km;
                }

                if (drive.greenFund) {
                  totalGreenFund += drive.greenFund;
                }
              }
            });

            setInvoiceTotal(total);
            setGreenFundTotal(totalGreenFund);
          }

          setInvoiceData(invoiceDetailsData);

          setPatchInvoiceData({
            ...patchInvoiceData,
            agency: invoiceDetailsData.agency?.shortName,
            booking: invoiceDetailsData.id,
            email: invoiceDetailsData.email ? invoiceDetailsData.email : '',
            first_name: invoiceDetailsData.firstName,
            green_fund: totalGreenFund,
            last_name: invoiceDetailsData.lastName,
            purpose: invoiceDetailsData.purpose,
            recipients: invoiceDetailsData.email ? [`${invoiceDetailsData.email}`] : [],
            reference_date: format(new Date(), DateFormat.ApiDate),
            requesting_unit: invoiceDetailsData.requestingUnit,
            total_duration: totalDuration || '00:00',
            total_km: totalMileage ? totalMileage.toString() : '0.00',
            total_number_of_passengers: invoiceDetailsData.nOfPassengers,
            transfer_type: invoiceDetailsData.transferType,
          });
        }
      },
      onError(error: any) {
        if (error.response) {
          toast.error(getErrors(error.response.data));
        }
      },
    },
  );

  const { mutate: createInvoice, isLoading: isCreatingInvoiceLoading } = useMutation<
    unknown,
    unknown,
    IIndividualInvoiceCreate
  >(
    'create-individual-invoice',
    (data) => financialRepository.createIndividualInvoice(facilityId, data),
    {
      onSuccess(data) {
        if (data) {
          setInvoiceData((prev: any) => ({ ...prev, ...patchInvoiceData }));
          setModalOpen(true);
          toast.success(t('invoice.invoiceCreated'));
        }
      },
      onError(error: any) {
        if (error.response) {
          toast.error(getErrors(error.response.data));
        }
      },
    },
  );

  useEffect(() => {
    let total =
      invoiceData?.driveSet.reduce((prev, current) => prev + (current.price || 0), 0) || 0;

    if (patchInvoiceData.charges.length) {
      patchInvoiceData.charges.forEach((data) => {
        total += data.quantity * data.unit_price;
      });
    }

    setInvoiceTotal(total % 1 === 0 ? total : parseFloat(total.toFixed(2)));
  }, [patchInvoiceData, invoiceData?.driveSet]);

  return (
    <>
      <section className="create-invoice-page">
        <div className="header">
          <h1 className="title">
            {t('invoice.pageTitle')}: {city} | {agencyName}
          </h1>
        </div>
        {isInvoiceDataLoading ? (
          <Loader fullScreen spinning />
        ) : (
          <>
            <div className="row">
              <FormPanel
                className="form-panel"
                header={
                  <div>
                    <div>
                      <small>{t('invoice.individualInvoiceFor')}</small>
                      <h3>
                        {`${invoiceData?.firstName} ${invoiceData?.lastName} - ${agencyName}`}
                        <span>Ref code: {invoiceData?.refCode}</span>
                      </h3>
                    </div>
                    <Button
                      text={t('common.btnBack')}
                      variant="transparent"
                      onClick={() => navigate(-1)}
                    />
                  </div>
                }
                title={
                  <>
                    <UserIcon /> {t('invoice.passengerDetails')}
                  </>
                }
              >
                <PassengerDetails data={invoiceData} />
              </FormPanel>
            </div>
            <div className="row">
              <FormPanel
                className="form-panel"
                title={
                  <>
                    <TripsDetailsIcon /> {t('invoice.tripsDetails')}
                  </>
                }
              >
                <TripsDetails data={invoiceData} />
              </FormPanel>
            </div>
            <div className="row">
              <FormPanel
                className="form-panel"
                title={
                  <>
                    <ServicesIcon /> {t('invoice.charges')}
                  </>
                }
              >
                {invoiceData && currencies && paymentMethods && (
                  <InvoiceCharges
                    currencies={currencies}
                    paymentMethods={paymentMethods}
                    storedCharges={patchInvoiceData.charges}
                    onChangeCurrency={(value) => {
                      setPatchInvoiceData({ ...patchInvoiceData, invoice_currency: value });
                    }}
                    onChangePayment={(value) => {
                      setPatchInvoiceData({ ...patchInvoiceData, payment_method: value });
                    }}
                    updateInvoice={(data) => {
                      setPatchInvoiceData({ ...patchInvoiceData, charges: data });
                    }}
                  />
                )}
              </FormPanel>
            </div>

            <div className="balance">
              {greenFundTotal > 0 && (
                <Balance label={t('invoice.greenFundIncluded')} value={`${greenFundTotal}`} />
              )}
              <Balance label={t('invoice.invoiceTotal')} value={`${invoiceTotal}`} wide />
            </div>

            <div className="row row-2-columns">
              <FormPanel
                className="col-left comments"
                title={
                  <>
                    <CommentsIcon /> {t('invoice.comments')}
                  </>
                }
              >
                <Comments
                  updateInvoice={(data) => setPatchInvoiceData({ ...patchInvoiceData, ...data })}
                />
              </FormPanel>

              <FormPanel
                className="col-right"
                title={
                  <>
                    <LetterIcon /> {t('invoice.sendCopy')}
                  </>
                }
              >
                {invoiceData && (
                  <SendCopy
                    defaultValue={invoiceData.email}
                    updateInvoice={(data) => setPatchInvoiceData({ ...patchInvoiceData, ...data })}
                  />
                )}
              </FormPanel>
            </div>

            <div className="row">
              <Button
                disabled={isCurrencyLoading || isPaymentLoading}
                text={t('invoice.createInvoice')}
                variant="submit"
                onClick={() => {
                  createInvoice(patchInvoiceData);
                }}
              />
            </div>
          </>
        )}
      </section>

      <Modal variant="default" visible={modalOpen}>
        <div className="invoice-created-modal">
          {isCreatingInvoiceLoading ? (
            <Loader spinning />
          ) : (
            <>
              <SuccessIcon className="icon" />
              <p className="greeting">{t('invoice.individualInvoiceCreatedTitle')}</p>
              <Button
                className="button"
                text={t('common.btnDone')}
                variant="submit"
                onClick={() => {
                  setModalOpen(false);
                  navigate(-1);
                }}
              />
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default CreateIndividualInvoicePage;
