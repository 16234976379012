import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@unbooking/ui-modules';
import { VehicleType } from '@common/interfaces';
import { formatDateString } from '@common/utils';
import {
  Armoured,
  Edit,
  ReadOnly,
  Vehicle as VehicleIcon,
  VehicleChassis,
  VehicleKm,
  VehiclePax,
  VehiclePlaceholder,
  VehicleRoof,
} from '@assets/svg/icons';
import './styles.scss';

export interface VehicleItem {
  isEditable?: boolean;
  isLoading?: boolean;
  vehicle: VehicleType;
  onVehicleEdit?: (vehicleId: string) => void;
  onVehicleStatusChange?: (vehicle: VehicleType) => void;
}

const Vehicle: FC<VehicleItem> = ({
  isEditable,
  isLoading,
  vehicle,
  onVehicleEdit,
  onVehicleStatusChange,
}) => {
  const { t } = useTranslation();

  const {
    agency,
    chassisNumber,
    currentUnavailability,
    id,
    label,
    maxCapacity,
    model,
    plateNumber,
    protection,
    roofNumber,
    totalKm,
    sharedAgenciesCount: sharedCount,
  } = vehicle;

  return (
    <div className="vehicle-wrap">
      <div className="vehicle">
        <div className="vehicle-image">
          {model?.image ? (
            <img src={model?.image} alt={model?.name} data-testid="vehicle-image" />
          ) : (
            <div className="vehicle-image-placeholder">
              <VehiclePlaceholder />
            </div>
          )}

          {currentUnavailability?.deleted && (
            <div className="vehicle-status">
              {t('common.disable')}{' '}
              <div className="vehicle-status-text">
                {`${t('common.from')} ${formatDateString(
                  currentUnavailability?.sinceDate,
                  'yyyy-MM-dd',
                  'dd/MM/yyyy',
                )}`}
              </div>
            </div>
          )}

          {currentUnavailability?.sinceDate && currentUnavailability?.untilDate ? (
            <div className="vehicle-status">
              {t('mobility.unavailable')}
              <div className="vehicle-status-text">
                {`${t('common.from')} ${formatDateString(
                  currentUnavailability?.sinceDate,
                  'yyyy-MM-dd',
                  'dd/MM/yyyy',
                )} ${t('common.to')} ${formatDateString(
                  currentUnavailability?.untilDate,
                  'yyyy-MM-dd',
                  'dd/MM/yyyy',
                )}`}
              </div>
            </div>
          ) : null}
        </div>

        <div className="vehicle-details">
          <div className="vehicle-details-header">
            <div className="vehicle-details-agency">
              {agency?.logo ? (
                <img src={agency?.logo} alt={agency?.shortName} />
              ) : (
                agency?.shortName
              )}
            </div>

            <div className="vehicle-details-title">
              {model ? model.name : label}
              {protection === 'armoured' && (
                <div className="armoured">
                  <div className="icon">
                    <Armoured />
                  </div>
                  {t('mobility.msgShareArmoured')}
                </div>
              )}
            </div>

            {sharedCount ? (
              <div className="vehicle-details-shared">{t('mobility.shared')}</div>
            ) : null}

            <div className="vehicle-details-controls">
              <Button
                className="btn-edit"
                data-testid="mobility-edit-vehicle"
                icon={isEditable ? <Edit /> : <ReadOnly />}
                text={isEditable ? t('common.btnEdit') : t('common.btnView')}
                variant="icon"
                onClick={() => onVehicleEdit?.(id)}
              />
            </div>
          </div>

          <div className="vehicle-details-content">
            <div className="item">
              <div className="icon">
                <VehicleIcon />
              </div>
              <div>
                <div className="label">{t('mobility.plateNumber')}</div>
                <div className="value">{plateNumber}</div>
              </div>
            </div>

            <div className="item">
              <div className="icon">
                <VehicleKm />
              </div>
              <div>
                <div className="label">{t('mobility.totalKm')}</div>
                <div className="value">{totalKm}</div>
              </div>
            </div>

            <div className="item">
              <div className="icon">
                <VehicleRoof />
              </div>
              <div>
                <div className="label">{t('mobility.roofNumber')}</div>
                <div className="value">{roofNumber}</div>
              </div>
            </div>

            <div className="item">
              <div className="icon">
                <VehicleChassis />
              </div>
              <div>
                <div className="label">{t('mobility.chassisNumber')}</div>
                <div className="value">{chassisNumber}</div>
              </div>
            </div>

            <div className="item">
              <div className="icon">
                <VehiclePax />
              </div>
              <div>
                <div className="label">N°Pax</div>
                <div className="value">{maxCapacity}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isEditable && (
        <div className="vehicle-ctrl">
          {currentUnavailability ? (
            <Button
              className="btn btn-available"
              disabled={isLoading!}
              text={t('mobility.btnMarkAvailable')}
              variant="secondary"
              onClick={() => onVehicleStatusChange?.(vehicle)}
            />
          ) : (
            <Button
              className="btn btn-unavailable"
              text={t('mobility.btnMarkUnavailable')}
              variant="danger"
              onClick={() => onVehicleStatusChange?.(vehicle)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Vehicle;
