import { type FC, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { format } from 'date-fns';

import { Button, PageTitle, Pagination } from '@unbooking/ui-modules';
import { useFacility, useFeatureFlags } from '@common/hooks';
import { useRepository } from '@context';
import { Serializer, getErrors } from '@common/utils';
import { ApiList, VehicleType, VehicleApi, VehicleCreate, VehicleUpdate } from '@common/interfaces';
import { Modal, Loader, Tabs, UnavailabilityForm, Vehicle, VehicleEditForm } from '@components';
import './styles.scss';

const VehiclesListPage: FC = () => {
  const { flags } = useFeatureFlags();
  const { mobilityRepository } = useRepository();
  const { t } = useTranslation();
  const { agencyName, facility, facilityId } = useFacility();
  const { agencyId, city, country } = facility;
  const tabs = ['My Vehicles', 'Shared'];

  const [isLoadingProcess, setLoadingProcess] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>(tabs[0]);
  const [selectedVehicle, setSelectedVehicle] = useState<string | null>(null);
  const [showVehicleForm, setShowVehicleForm] = useState<boolean>(false);
  const [vehicleUnavailable, setVehicleUnavailable] = useState<VehicleType | null>(null);

  const [vehicleList, setVehicleList] = useState<VehicleType[]>([]);
  const [vehicleTotalItems, setVehicleTotalItems] = useState<number>(0);
  const [vehicleSelectedPage, setVehicleSelectedPage] = useState<number>(1);
  const [vehiclePageSize, setVehiclePageSize] = useState<number>(10);
  const [sharedVehicleList, setSharedVehicleList] = useState<VehicleType[]>([]);
  const [sharedVehicleTotalItems, setSharedVehicleTotalItems] = useState<number>(0);
  const [sharedVehicleSelectedPage, setSharedVehicleSelectedPage] = useState<number>(1);
  const [sharedVehiclePageSize, setSharedVehiclePageSize] = useState<number>(10);

  const { isLoading: isVehiclesLoading, refetch: getVehicles } = useQuery(
    ['vehicle-list', vehiclePageSize, vehicleSelectedPage],
    () =>
      mobilityRepository.getVehicles(facilityId, {
        agencyId,
        limit: vehiclePageSize,
        offset: (vehicleSelectedPage - 1) * 10,
      }),
    {
      enabled: !!agencyId,
      onSuccess: (data: ApiList<VehicleApi>) => {
        setLoadingProcess(false);
        setVehicleList(data.results.map(Serializer.formatVehicle));
        setVehicleTotalItems(data.count);
      },
    },
  );

  const { isLoading: isSharedVehiclesLoading, refetch: getSharedVehicles } = useQuery(
    ['shared-vehicle-list', sharedVehiclePageSize, sharedVehicleSelectedPage],
    () =>
      mobilityRepository.getVehicles(
        facilityId,
        {
          agencyId,
          limit: sharedVehiclePageSize,
          offset: (sharedVehicleSelectedPage - 1) * 10,
        },
        true,
      ),
    {
      enabled: !!agencyId,
      onSuccess: (data: ApiList<VehicleApi>) => {
        setSharedVehicleList(data.results.map(Serializer.formatVehicle));
        setSharedVehicleTotalItems(data.count);
      },
    },
  );

  const { mutate: deleteUnavailabilities } = useMutation<
    unknown,
    AxiosError,
    { id: string; unavailabilityId: string }
  >(
    'delete-unavailabilities',
    ({ id, unavailabilityId }) =>
      mobilityRepository.deleteUnavailabilities(facilityId, id, unavailabilityId, 'vehicle'),
    {
      onSuccess: () => {
        if (selectedTab === tabs[0]) getVehicles();
        if (selectedTab === tabs[1]) getSharedVehicles();
      },
    },
  );

  const { isLoading: isVehicleCreating, mutateAsync: createVehicle } = useMutation<
    unknown,
    AxiosError,
    VehicleCreate
  >('create-vehicle', (data) => mobilityRepository.createVehicle(facilityId, data), {
    onSuccess: () => {
      toast.success(t('mobility.msgVehicleCreated'));
      setShowVehicleForm(false);
      if (selectedTab === tabs[0]) getVehicles();
      if (selectedTab === tabs[1]) getSharedVehicles();
    },
    onError: (e: any) => {
      if (e.message) toast.error(e.response.data || t('common.errorMsgDefault'));
    },
  });

  const { isLoading: isVehicleUpdating, mutateAsync: updateVehicle } = useMutation<
    unknown,
    AxiosError,
    { vehicleId: string; data: VehicleUpdate }
  >(
    'update-vehicle',
    ({ vehicleId, data }) => mobilityRepository.updateVehicle(facilityId, vehicleId, data),
    {
      onSuccess: () => {
        toast.success(t('mobility.msgVehicleUpdated'));
        setShowVehicleForm(false);
        setSelectedVehicle(null);
        if (selectedTab === tabs[0]) getVehicles();
        if (selectedTab === tabs[1]) getSharedVehicles();
      },
      onError: (e: any) => {
        if (e.message) toast.error(getErrors(e.response.data) || t('common.errorMsgDefault'));
      },
    },
  );

  const onVehicleStatusChange = (vehicle: VehicleType): void => {
    if (vehicle?.currentUnavailability) {
      deleteUnavailabilities({
        id: vehicle.id,
        unavailabilityId: vehicle.currentUnavailability.id,
      });
      setLoadingProcess(true);
    } else {
      setVehicleUnavailable(vehicle);
    }
  };

  useEffect(() => {
    if (selectedVehicle) setShowVehicleForm(true);
  }, [selectedVehicle]);

  return (
    <section className="hbh-container vehicle-list">
      <PageTitle
        bottomLine
        className="vehicle-list-title"
        filters={
          <div>
            {flags.addDriverAndVehicle?.is_active && (
              <Button
                className="btn btn-add"
                text={t('mobility.addVehicle')}
                variant="primary"
                onClick={() => {
                  setShowVehicleForm(true);
                }}
              />
            )}
          </div>
        }
        title={
          city && country
            ? `${t('mobility.vehiclesListTitle')} ${city}, ${country} | ${agencyName} View`
            : ''
        }
      />

      <Tabs
        badges={[vehicleTotalItems, sharedVehicleTotalItems]}
        className="vehicle-list-tabs"
        selectedTab={tabs.indexOf(selectedTab)}
        tabs={tabs}
        onTabSelect={setSelectedTab}
      />

      {selectedTab === tabs[0] ? (
        <div className="row">
          <Loader fullScreen spinning={isVehiclesLoading}>
            {!isVehiclesLoading && !vehicleTotalItems ? (
              <div className="empty-list">{t('common.emptyList')} ...</div>
            ) : (
              <>
                {vehicleList.map((vehicle: VehicleType) => (
                  <Vehicle
                    isEditable
                    isLoading={isLoadingProcess}
                    key={vehicle.id}
                    vehicle={vehicle}
                    onVehicleEdit={setSelectedVehicle}
                    onVehicleStatusChange={onVehicleStatusChange}
                  />
                ))}

                <div className="vehicle-list-footer">
                  {!isVehiclesLoading && (
                    <div className="last-update">
                      {t('common.lastUpdate')}: {format(new Date(), 'dd/MM/yyyy HH:mm')}
                    </div>
                  )}

                  {vehicleTotalItems > 10 && (
                    <Pagination
                      className="pagination"
                      selectedPage={vehicleSelectedPage}
                      showJumper
                      showPageSize
                      totalPages={vehicleTotalItems}
                      variant="light"
                      onPageChange={setVehicleSelectedPage}
                      onPageSizeChange={setVehiclePageSize}
                    />
                  )}
                </div>
              </>
            )}
          </Loader>
        </div>
      ) : null}

      {selectedTab === tabs[1] ? (
        <div className="row">
          <Loader fullScreen spinning={isSharedVehiclesLoading}>
            {!isSharedVehiclesLoading && !sharedVehicleTotalItems ? (
              <div className="empty-list">{t('common.emptyList')} ...</div>
            ) : (
              <>
                {sharedVehicleList.map((vehicle: VehicleType) => (
                  <Vehicle key={vehicle.id} vehicle={vehicle} onVehicleEdit={setSelectedVehicle} />
                ))}

                <div className="vehicle-list-footer">
                  {!isSharedVehiclesLoading && (
                    <div className="last-update">
                      {t('common.lastUpdate')}: {format(new Date(), 'dd/MM/yyyy HH:mm')}
                    </div>
                  )}

                  {sharedVehicleTotalItems > 10 && (
                    <Pagination
                      className="pagination"
                      selectedPage={sharedVehicleSelectedPage}
                      showJumper
                      showPageSize
                      totalPages={sharedVehicleTotalItems}
                      variant="light"
                      onPageChange={setSharedVehicleSelectedPage}
                      onPageSizeChange={setSharedVehiclePageSize}
                    />
                  )}
                </div>
              </>
            )}
          </Loader>
        </div>
      ) : null}

      {vehicleUnavailable && (
        <Modal
          showBtnClose
          variant="dark"
          visible={!!vehicleUnavailable}
          onClose={() => setVehicleUnavailable(null)}
        >
          <UnavailabilityForm
            id={vehicleUnavailable.id}
            label={
              vehicleUnavailable.model ? vehicleUnavailable.model.name : vehicleUnavailable.label
            }
            type="vehicle"
            onSubmit={() => {
              if (selectedTab === tabs[0]) getVehicles();
              if (selectedTab === tabs[1]) getSharedVehicles();
              setVehicleUnavailable(null);
            }}
          />
        </Modal>
      )}

      <Modal
        showBtnClose
        variant="light"
        visible={showVehicleForm}
        onClose={() => {
          setShowVehicleForm(false);
          if (selectedVehicle) setSelectedVehicle(null);
        }}
      >
        <VehicleEditForm
          isLoading={isVehicleCreating || isVehicleUpdating}
          readOnly={selectedTab === tabs[1]}
          vehicleId={selectedVehicle}
          createVehicle={createVehicle}
          updateVehicle={updateVehicle}
        />
      </Modal>
    </section>
  );
};

export default VehiclesListPage;
