import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LocalStorageKeys } from '@common/constants';
import { AvailableLocations } from '@common/interfaces';
import { Explore, Home, VehiclePlaceholder } from '@assets/svg/icons';
import './Nav.styles.scss';

const UNBH_HOME_LINK = process.env.REACT_APP_UNBH_HOME_LINK;

interface NavProps {
  location?: AvailableLocations;
}

const Nav: FC<NavProps> = ({ location }) => {
  const { id: facilityId } = useParams<{ id: string }>();
  const { i18n, t } = useTranslation();
  const lang = i18n.language;
  const authType = localStorage.getItem(LocalStorageKeys.AUTH_TYPE)!;
  const { cityId, isoCode } = location || {};

  return (
    <nav className="nav">
      <a
        className="nav-item"
        href={`${UNBH_HOME_LINK}/?seamless=True&provider=is_${authType}_user`}
        rel="noreferrer"
      >
        <Home className="icon" /> {t('crumbs.home')}
      </a>
      <a className="nav-item" href={`${UNBH_HOME_LINK}/${lang}/explore`} rel="noreferrer">
        <Explore className="icon" />
        {t('crumbs.explore')!}
      </a>
      <a
        className="nav-item"
        href={`${UNBH_HOME_LINK}/${lang}/countries/${isoCode}/locations/${cityId?.toLowerCase()}/facilities/${facilityId}/detail/`}
        rel="noreferrer"
      >
        <VehiclePlaceholder className="icon" />
        {t('bookingForm.details')}
      </a>
    </nav>
  );
};

export default Nav;
