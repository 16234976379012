/* istanbul ignore file */
import { areIntervalsOverlapping, compareAsc, differenceInMinutes, isEqual, parse } from 'date-fns';
import { IDropdownOption } from '@ui-modules/types';
import {
  DriveCreate,
  FormDrive,
  PlAcceptDrive,
  PlDrive,
  TransferType,
  VerifiedShuttleType,
} from '@common/interfaces';
import { DateFormat as DF } from '@common/types';
import { getFullDate } from './date';

const checkRangeOverlap = (a: DriveCreate, b: DriveCreate): boolean =>
  a.driver.value !== b.driver.value ||
  a.vehicle.value !== b.vehicle.value ||
  areIntervalsOverlapping(
    {
      start: getFullDate(a.pickupDate, a.pickupTime),
      end: getFullDate(a.dropoffDate, a.dropoffTime),
    },
    {
      start: getFullDate(b.pickupDate, b.pickupTime),
      end: getFullDate(b.dropoffDate, b.dropoffTime),
    },
  );

export const calcSharedDrive = ({
  driver,
  drives,
  dropoffDate,
  dropoffTime,
  pickupDate,
  pickupTime,
  vehicle,
}: {
  driver?: IDropdownOption;
  drives: PlDrive[];
  dropoffDate?: Date;
  dropoffTime?: Date;
  pickupDate?: Date;
  pickupTime?: Date;
  vehicle?: IDropdownOption;
}): PlDrive | null => {
  if (!dropoffDate || !dropoffTime || !pickupDate || !pickupTime) return null;

  const selectedDriveStart = getFullDate(pickupDate, pickupTime);
  const selectedDriveEnd = getFullDate(dropoffDate, dropoffTime);

  const sharedDrive = drives.find((d: PlDrive) => {
    if (!driver) return d.vehicleId === vehicle?.value;
    if (vehicle?.value !== d.vehicleId) return false;

    const existStart = parse(`${d.pickupDate} ${d.pickupTime}`, DF.ApiDateAltFull, new Date());
    const existEnd = parse(`${d.dropoffDate} ${d.dropoffTime}`, DF.ApiDateAltFull, new Date());

    return (
      !isEqual(existStart, selectedDriveEnd) &&
      !isEqual(existEnd, selectedDriveStart) &&
      differenceInMinutes(selectedDriveEnd, existStart) > 0 &&
      differenceInMinutes(existEnd, selectedDriveStart) > 0
    );
  });

  return sharedDrive || null;
};

export const checkConflictDrives = ({
  driver,
  drives,
  dropoffDate,
  dropoffTime,
  pickupDate,
  pickupTime,
  transferType,
  vehicle,
}: {
  driver?: IDropdownOption;
  drives: PlDrive[];
  dropoffDate?: Date;
  dropoffTime?: Date;
  pickupDate?: Date;
  pickupTime?: Date;
  transferType?: TransferType;
  vehicle?: IDropdownOption;
}): 'driver' | 'vehicle' | null => {
  if (!dropoffDate || !dropoffTime || !pickupDate || !pickupTime) return null;

  let conflict = null;

  const relevantDrives = drives.filter(
    (d) => d.vehicleId === vehicle?.value || d.driverId === driver?.value,
  );

  const selectedStart = getFullDate(pickupDate, pickupTime);
  const selectedEnd = getFullDate(dropoffDate, dropoffTime);

  relevantDrives.forEach((drive: PlDrive) => {
    if (transferType === TransferType.Shuttle && drive.scheduledRouteId !== null) return;

    const start = parse(`${drive.pickupDate} ${drive.pickupTime}`, DF.ApiDateAltFull, new Date());
    const end = parse(`${drive.dropoffDate} ${drive.dropoffTime}`, DF.ApiDateAltFull, new Date());

    if (
      areIntervalsOverlapping({ start: selectedStart, end: selectedEnd }, { start, end }) &&
      !(drive.vehicleId === vehicle?.value && drive.driverId === driver?.value)
    ) {
      conflict = drive.vehicleId === vehicle?.value ? 'vehicle' : 'driver';
    }
  });

  return conflict;
};

export const checkConflictDriveOnEdit = (drives: FormDrive[]): boolean => {
  if (drives.length <= 1) return false;

  const overlappingDrives = drives.map((drive: FormDrive, idx: number) => {
    const comparedStart = getFullDate(drive.pickupDate, drive.pickupTime);
    const comparedEnd = getFullDate(drive.dropoffDate, drive.dropoffTime);

    return drives
      .filter((_, i: number) => i !== idx)
      .map((d: FormDrive) => {
        const start = getFullDate(d.pickupDate, d.pickupTime);
        const end = getFullDate(d.dropoffDate, d.dropoffTime);

        if (compareAsc(comparedEnd, start) <= 0) return false;

        return areIntervalsOverlapping({ start: comparedStart, end: comparedEnd }, { start, end });
      });
  });

  return overlappingDrives.flat().includes(true);
};

export const checkDriveIsRideShared = ({
  data,
  drives,
}: {
  data: PlAcceptDrive;
  drives: PlDrive[];
}): boolean => {
  const { driverId, dropoffDate, dropoffTime, pickupDate, pickupTime, vehicleId } = data;
  const relevantDrives = drives.filter((d) => d.vehicleId === vehicleId || d.driverId === driverId);

  const acceptRange = {
    start: parse(`${pickupDate} ${pickupTime}`, `${DF.ApiDate} ${DF.ApiTime}`, new Date()),
    end: parse(`${dropoffDate} ${dropoffTime}`, `${DF.ApiDate} ${DF.ApiTime}`, new Date()),
  };

  const rideShared = relevantDrives.map((d: PlDrive) => {
    const driveRange = {
      start: parse(`${d.pickupDate} ${d.pickupTime}`, DF.ApiDateAltFull, new Date()),
      end: parse(`${d.dropoffDate} ${d.dropoffTime}`, DF.ApiDateAltFull, new Date()),
    };

    return (
      areIntervalsOverlapping(acceptRange, driveRange) &&
      d.vehicleId === vehicleId &&
      d.driverId === driverId
    );
  });

  return rideShared.includes(true);
};

export const checkOverlappingDrives = (drives: DriveCreate[]): boolean =>
  drives?.length > 1
    ? drives.some((d, i) => drives.slice(i + 1).some((drive) => checkRangeOverlap(d, drive)))
    : false;

export const checkShuttleConflictRange = ({
  driver,
  drives,
  end,
  start,
  vehicle,
}: {
  driver: string;
  drives: PlDrive[];
  end: Date;
  start: Date;
  vehicle: string;
}): 'driver' | 'time' | null => {
  const scheduled = drives.find((d) => {
    const driveRange = {
      start: parse(`${d.pickupDate} ${d.pickupTime}`, DF.ApiDateAltFull, new Date()),
      end: parse(`${d.dropoffDate} ${d.dropoffTime}`, DF.ApiDateAltFull, new Date()),
    };

    return (
      d.vehicleId === vehicle &&
      d.scheduledRouteId !== null &&
      areIntervalsOverlapping({ start, end }, driveRange)
    );
  });

  if (scheduled) {
    const scheduledStart = parse(
      `${scheduled.pickupDate} ${scheduled.pickupTime}`,
      DF.ApiDateAltFull,
      new Date(),
    );
    const scheduledEnd = parse(
      `${scheduled.dropoffDate} ${scheduled.dropoffTime}`,
      DF.ApiDateAltFull,
      new Date(),
    );

    if (scheduled.driverId !== driver) return 'driver';
    if (start < scheduledStart || end > scheduledEnd) return 'time';
  }

  return null;
};

export const checkShuttleType = ({
  drives,
  end,
  start,
  transferType,
  vehicle,
}: {
  drives: PlDrive[];
  end: Date;
  start: Date;
  transferType: TransferType;
  vehicle: string;
}): VerifiedShuttleType | null => {
  const hasShuttleOverlap = drives.find((d) => {
    const driveRange = {
      start: parse(`${d.pickupDate} ${d.pickupTime}`, DF.ApiDateAltFull, new Date()),
      end: parse(`${d.dropoffDate} ${d.dropoffTime}`, DF.ApiDateAltFull, new Date()),
    };

    return (
      d.scheduledRouteId &&
      d.vehicleId === vehicle &&
      areIntervalsOverlapping({ start, end }, driveRange)
    );
  });

  if (!hasShuttleOverlap && transferType === TransferType.Shuttle) {
    return { scheduledRouteId: null, transferType: TransferType.InTown };
  }
  if (hasShuttleOverlap) {
    return {
      scheduledRouteId: hasShuttleOverlap.scheduledRouteId || null,
      transferType: TransferType.Shuttle,
      typeChanged: transferType !== TransferType.Shuttle,
    };
  }

  return null;
};

type Validator = (value: unknown) => string | void;
type ValidatorExt = (value: unknown, values: Record<string, unknown>) => unknown | void;
export const validateWithPax =
  (validator: Validator): ValidatorExt =>
  (value: unknown, { mainInfo }: Record<string, any>) => {
    if (!mainInfo?.noPax) return validator(value);
  };
