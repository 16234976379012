import type { FC } from 'react';
import { useFieldState } from 'informed';
import { Dropdown } from '@ui-modules/informed';
import { IDropdownOption } from '@ui-modules/types';

interface VehicleSharedAgencyProps {
  disabled?: boolean;
  options?: IDropdownOption[];
}

const VehicleSharedAgencyField: FC<VehicleSharedAgencyProps> = ({ disabled, options = [] }) => {
  const { value: protection } = useFieldState('protection');

  const isArmoured = (protection as IDropdownOption)?.value === 'armoured';

  return (
    <div className="field vehicle-shared-agency">
      <Dropdown
        isDisabled={disabled || isArmoured}
        isMulti
        name="sharedLocations"
        options={options}
      />
      {isArmoured && <div className="field-error">{`Armoured vehicle can't be shared`}</div>}
    </div>
  );
};

export default VehicleSharedAgencyField;
