import { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';

import { Footer } from '@ui-modules';
import { useAuth } from '@unbooking/ui-auth';
import { RepositoryProvider, useGlobalContext } from '@context';
import { LocalStorageKeys, SessionStorageKeys } from '@common/constants';
import { Crumb } from '@common/interfaces';
import { Header, MaintenanceBanner } from '@components';
import { CallbackPage, ErrorPage, NoAccessPage, SignInPage, NotFoundPage } from '@pages';
import { useFacilityPermission, useUserInfo } from '@common/hooks';
import { processLogoutNonFederatedUser } from '@common/utils';
import i18n from '@common/translations/i18n';
import { RootLayout } from '@layout';
import { routes } from './routes';
import pkg from '../package.json';

const BOOKING_FORM_URL = process.env.REACT_APP_BOOKING_FORM_URL;
const DO_URL = process.env.REACT_APP_DO_URL;
const MAINTENANCE_BANNER = process.env.REACT_APP_MAINTENANCE_SWITCH;
const UNDSS_URL = process.env.REACT_APP_UNDSS_URL;
const UNBH_DO = process.env.REACT_APP_HBH_DO_URL;
const UNBH_HOME = process.env.REACT_APP_UNBH_HOME_LINK;

const releaseVersion = `v.${pkg.version} ${
  process.env.REACT_APP_BUILD ? `| rev.${process.env.REACT_APP_BUILD}` : ''
}`;
const traceTargets = process.env.REACT_APP_SENTRY_TRACE_PROPAGATION_TARGETS
  ? process.env.REACT_APP_SENTRY_TRACE_PROPAGATION_TARGETS.split(',')
  : undefined;

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  enabled: ['prod', 'qa'].includes(process.env.REACT_APP_ENV_NAME!),
  environment: process.env.REACT_APP_ENV_NAME,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({ maskAllText: true, blockAllMedia: true }),
  ],
  release: releaseVersion,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1,
  sampleRate: 0.05,
  tracePropagationTargets: traceTargets,
  tracesSampleRate: 0.05,
});

const RouterElement = ({ route }: { route: Crumb }) => (
  <RootLayout routes={routes} route={route} homeUrl={DO_URL!} node={<ToastContainer />} />
);

function App() {
  const federatedUserToken = localStorage.getItem(LocalStorageKeys.X_USER_TOKEN);
  const location = useLocation();
  const navigate = useNavigate();
  const { axios, isAuthenticated, logout: logoutSSO } = useAuth();
  const { firstName, isDfp, lastName } = useUserInfo();
  const { lang, setLang } = useGlobalContext();

  useFacilityPermission();

  const [selectedLang, setSelectedLang] = useState<string>(lang);

  const goToHome = (params?: string) =>
    navigate(params ? `/${location.search}` : '/', { replace: true });

  const logoutHandler = () => {
    if (federatedUserToken) {
      return processLogoutNonFederatedUser();
    }

    logoutSSO?.();
    goToHome();
  };

  const path = window.location.pathname;
  const isDDORoute = path.startsWith(`${DO_URL}`);
  const isNestedRoute =
    path.startsWith(`/${UNDSS_URL}/profile`) || path.startsWith(`/${BOOKING_FORM_URL}/`);

  useEffect(() => {
    i18n.changeLanguage(selectedLang);
    sessionStorage.setItem(SessionStorageKeys.SELECTED_LANGUAGE, selectedLang);
    setLang(selectedLang);
  }, [selectedLang, setLang]);

  useEffect(() => {
    const redirectUrl = sessionStorage.getItem(SessionStorageKeys.REDIRECT_URL);

    if (
      !localStorage.getItem(LocalStorageKeys.LOGIN_IN_PROGRESS) &&
      !sessionStorage.getItem(SessionStorageKeys.AGENCY) &&
      !sessionStorage.getItem(SessionStorageKeys.FACILITY) &&
      !isDDORoute &&
      !isNestedRoute &&
      !redirectUrl
    ) {
      goToHome();
    }

    if (!isAuthenticated && (isNestedRoute || isDDORoute)) {
      sessionStorage.setItem(SessionStorageKeys.REDIRECT_URL, `${path}${location.search}`);
      goToHome(location.search);
    }

    if (isAuthenticated && redirectUrl) {
      navigate(redirectUrl);
      sessionStorage.removeItem(SessionStorageKeys.REDIRECT_URL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RepositoryProvider axios={axios}>
      {isAuthenticated ? (
        <>
          <MaintenanceBanner switchName={MAINTENANCE_BANNER!} />
          <Header
            activeLang={selectedLang}
            languages={['en', 'fr']}
            logout={logoutHandler}
            username={`${firstName} ${lastName}`}
            isOffice={isDDORoute}
            officeUrl={isDfp ? DO_URL : null}
            onLangChange={setSelectedLang}
            onLogoClick={() => window.open(`${isDDORoute ? UNBH_DO : UNBH_HOME}`, '_self')}
          />
        </>
      ) : null}
      <Routes>
        {isAuthenticated ? (
          <>
            {routes.map((route) => (
              <Route path={route.path} key={route.path} element={<RouterElement route={route} />} />
            ))}

            <Route path="callback/*" element={<CallbackPage />} />
          </>
        ) : (
          <>
            <Route index element={<SignInPage />} />
            <Route path="callback/*" element={<CallbackPage />} />
            <Route path="forbidden" element={<NoAccessPage />} />
            <Route path="error" element={<ErrorPage />} />
          </>
        )}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      {isAuthenticated ? <Footer releaseVersion={releaseVersion} /> : null}
    </RepositoryProvider>
  );
}

export default App;
