/* eslint-disable react/no-unstable-nested-components */
import React, { FC, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, useFormApi } from 'informed';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { format, parse } from 'date-fns';

import { Button, Table, Pagination } from '@ui-modules';
import { Dropdown, TextField } from '@ui-modules/informed';
import { IDropdownOption } from '@ui-modules/types';
import { useRepository } from '@context';
import { MONTHS, CURRENT_MONTH, YEARS, CURRENT_YEAR } from '@common/constants';
import { ApiList, IDriveSetTable, IndInvoiceList, IndInvoiceListApi } from '@common/interfaces';
import { useFacility } from '@common/hooks';
import { DateFormat } from '@common/types';
import { addTimeToTime, getErrors, Serializer } from '@common/utils';
import { Search } from '@assets/svg/icons';
import './styles.scss';

interface ISearchData {
  current_month: string;
  current_year: string;
  keyword: string;
  page: string;
  passenger_agency: string;
}

enum ReducerActionType {
  AGENCY = 'AGENCY',
  CLEAR = 'CLEAR',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  PAGE = 'PAGE',
  KEYWORD = 'KEYWORD',
}

interface IReducerAction {
  type: ReducerActionType;
  payload?: string;
}

const initialSearchData: ISearchData = {
  keyword: '',
  current_month: CURRENT_MONTH.value,
  current_year: CURRENT_YEAR.value,
  page: '1',
  passenger_agency: '',
};

const searchDataReducer = (state: ISearchData, action: IReducerAction): ISearchData => {
  switch (action.type) {
    case ReducerActionType.AGENCY:
      return { ...state, passenger_agency: action.payload || '', page: '1' };
    case ReducerActionType.MONTH:
      return { ...state, current_month: action.payload || '', page: '1' };
    case ReducerActionType.YEAR:
      return { ...state, current_year: action.payload || '', page: '1' };
    case ReducerActionType.PAGE:
      return { ...state, page: action.payload || '' };
    case ReducerActionType.KEYWORD:
      return { ...state, keyword: action.payload || '' };
    case ReducerActionType.CLEAR:
      return { ...initialSearchData };
    default:
      return state;
  }
};

const IndividualInvoiceListPage: FC = () => {
  const {
    agencyName,
    facility: { agencyId, city },
    facilityId,
  } = useFacility();
  const navigate = useNavigate();
  const { financialRepository } = useRepository();
  const { t } = useTranslation();

  const [invoiceData, setInvoiceData] = useState<IndInvoiceList[]>([]);
  const [totalItems, setTotalItems] = useState(0);

  const [searchData, dispatch] = useReducer(searchDataReducer, initialSearchData);

  const months: IDropdownOption[] = [{ value: '', label: t('common.allMonths') }, ...MONTHS];
  const years: IDropdownOption[] = [{ value: '', label: t('common.allYears') }, ...YEARS];

  const { isLoading } = useQuery(
    ['get-bookings-for-invoices-list', searchData],
    () =>
      financialRepository.getBookingsForInvoiceList(facilityId, {
        agencyId,
        current_month: searchData.current_month,
        current_year: searchData.current_year,
        limit: 10,
        offset: (+searchData.page - 1) * 10,
        passenger_agency: searchData.passenger_agency,
        search: searchData.keyword,
      }),
    {
      enabled: !!agencyId,
      onError(error: any) {
        if (error.response) {
          toast.error(getErrors(error.response.data));
        }
      },
      onSuccess(data: ApiList<IndInvoiceListApi>) {
        setTotalItems(data.count);
        setInvoiceData(data.results.map(Serializer.formatIndividualInvoiceTable));
      },
    },
  );

  const { data: filtersData } = useQuery<{ passenger_agencies: IDropdownOption[] }>(
    ['get-invoice-filters'],
    () => financialRepository.getInvoiceFilters(facilityId),
    { refetchOnWindowFocus: false },
  );

  const agenciesOptions = [
    { label: t('common.allAgencies'), value: '' },
    ...(filtersData?.passenger_agencies || []),
  ];

  const getTotalDuration = (record: IndInvoiceList): string => {
    const init = '00:00:00';
    const totalDuration = record.driveSet
      .map((drive) => drive.duration)
      .filter((duration) => !!duration)
      .reduce((total, duration) => addTimeToTime(total, duration), init);

    return totalDuration !== init ? totalDuration : '-';
  };

  const onCreateInvoice = async (id: string): Promise<void> => {
    navigate(`${id}`);
  };

  const columns = [
    {
      dataIndex: 'pickupDate',
      key: 'pickupDate',
      title: t('report.performedOn'),
      render: (record: string) => (
        <span>
          {record
            ? format(parse(record, DateFormat.ApiDateAlt, new Date()), DateFormat.DateByDots)
            : ''}
        </span>
      ),
    },
    {
      key: 'name',
      title: t('common.passenger'),
      ellipsis: {
        showTitle: true,
      },
      render: (record: IndInvoiceList) => <span>{`${record.firstName} ${record.lastName}`}</span>,
    },
    {
      dataIndex: 'paxAgency',
      key: 'agency',
      title: t('common.paxAgency'),
      ellipsis: {
        showTitle: true,
      },
      render: (record: string) => <span className="text">{record || '-'}</span>,
    },
    {
      dataIndex: 'requestingUnit',
      key: 'requestingUnit',
      title: t('common.unit'),
      ellipsis: {
        showTitle: true,
      },
      render: (record: string) => <span>{record || '-'}</span>,
    },
    {
      dataIndex: 'nOfPassengers',
      key: 'nOfPassengers',
      title: 'Pax',
      render: (record: string) => <span>{record || '-'}</span>,
    },
    {
      dataIndex: 'transferType',
      key: 'transferType',
      title: t('common.transferType'),
      render: (record: string) => <span>{record ? record.replace('_', ' ') : '-'}</span>,
    },
    {
      dataIndex: 'driveSet',
      key: 'vehicle',
      title: t('common.vehicle'),
      render: (record: IDriveSetTable[]) => (
        <span className="text">{record ? record[0]?.vehicle : '-'}</span>
      ),
    },
    {
      key: 'driver',
      title: t('common.driver'),
      ellipsis: {
        showTitle: true,
      },
      render: (record: IndInvoiceList) => {
        const driver =
          record.driveSet.length && record.driveSet[0].driver ? `${record.driveSet[0].driver}` : '';
        return <span>{driver || '-'}</span>;
      },
    },
    {
      dataIndex: 'refCode',
      key: 'refCode',
      title: t('common.refCode'),
    },
    {
      dataIndex: 'driveSet',
      key: 'km',
      title: 'Km',
      render: (record: IDriveSetTable[]) => (
        <span>
          {record.length > 0 && record[0].km
            ? record.reduce((prev: number, current: IDriveSetTable) => prev + current.km, 0)
            : '-'}
        </span>
      ),
    },
    {
      key: 'duration',
      title: t('common.duration'),
      render: getTotalDuration,
    },
    {
      key: 'price',
      title: `${t('common.price')} $`,
      render: (record: IndInvoiceList) => (
        <span>
          {record.driveSet.length && record.driveSet[0].price !== undefined
            ? `$${record.driveSet.reduce(
                (prev: number, current: IDriveSetTable) => prev + current.price,
                0,
              )}`
            : '-'}
        </span>
      ),
    },
    {
      key: 'greenFund',
      title: `${t('common.greenFund')} ($)`,
      render: (record: IndInvoiceList) => (
        <span>
          {record.driveSet.length && record.driveSet[0].greenFund !== undefined
            ? `$${record.driveSet.reduce(
                (prev: number, current: IDriveSetTable) => prev + current.greenFund,
                0,
              )}`
            : '-'}
        </span>
      ),
    },
    {
      key: 'actions',
      title: '',
      render: (record: IndInvoiceList) => (
        <div className="actions">
          {record.pdf ? (
            <Button
              className="download"
              onClick={() => window.open(record.pdf)}
              text={t('common.btnDownloadInvoice')}
              variant="submit"
            />
          ) : (
            <Button
              className="create-invoice"
              text={t('invoice.createInvoice')}
              variant="primary"
              onClick={() => onCreateInvoice(record.id)}
            />
          )}
        </div>
      ),
    },
  ];

  const ResetButton: FC = React.memo(() => {
    const formApi = useFormApi();

    const handleReset = (): void => {
      formApi.setValue('keyword', initialSearchData.keyword);
      formApi.setValue('current_month', '');
      formApi.setValue('current_year', '');
      formApi.setValue('passenger_agency', '');

      dispatch({ type: ReducerActionType.CLEAR });
    };

    return (
      <Button
        className="btn-reset"
        text={t('common.btnResetFilters')}
        variant="link"
        onClick={handleReset}
      />
    );
  });

  return (
    <section className="invoice-list-page">
      <div className="header">
        <h1 className="title">{`${t('invoice.invoiceListTitle')}: ${city} | ${agencyName}`}</h1>
      </div>

      <div className="container">
        <div className="row">
          <Form
            className="form"
            onSubmit={(state) => {
              const { keyword } = state.values as Record<string, any>;
              dispatch({ type: ReducerActionType.KEYWORD, payload: keyword });
            }}
          >
            <div className="field-search">
              <TextField
                className="input"
                initialValue={initialSearchData.keyword}
                name="keyword"
                placeholder={t('invoice.inputInvoiceSearchByPaxAgency')}
              />
              <Button className="btn-search" icon={<Search />} type="submit" variant="primary" />
              <ResetButton />
            </div>

            <div className="filters">
              <div className="field agency">
                <Dropdown
                  name="passenger_agency"
                  label="Agency pax"
                  options={agenciesOptions}
                  placeholder={t('common.allAgencies')}
                  onChange={({ value }) => {
                    dispatch({ type: ReducerActionType.AGENCY, payload: value });
                  }}
                />
              </div>
              <div className="field month">
                <Dropdown
                  name="current_month"
                  label={t('common.month')}
                  options={months}
                  placeholder={t('common.allMonths')}
                  defaultValue={CURRENT_MONTH}
                  onChange={({ value }) => {
                    dispatch({ type: ReducerActionType.MONTH, payload: value });
                  }}
                />
              </div>
              <div className="field year">
                <Dropdown
                  name="current_year"
                  label={t('common.year')}
                  options={years}
                  placeholder={t('common.allYears')}
                  defaultValue={CURRENT_YEAR}
                  onChange={({ value }) => {
                    dispatch({ type: ReducerActionType.YEAR, payload: value });
                  }}
                />
              </div>
            </div>
          </Form>
        </div>

        <div className="row">
          <Table columns={columns} data={invoiceData} isLoading={isLoading} variant="light" />
        </div>

        <div className="footer">
          {totalItems > 10 ? (
            <Pagination
              selectedPage={+searchData.page}
              showJumper
              totalPages={totalItems}
              onPageChange={(selected: number): void =>
                dispatch({ type: ReducerActionType.PAGE, payload: selected.toString() })
              }
            />
          ) : (
            <div />
          )}
        </div>
      </div>
    </section>
  );
};

export default IndividualInvoiceListPage;
