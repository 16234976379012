import type { FC } from 'react';
import { t } from 'i18next';
import { TextField } from '@ui-modules/informed';
import { phoneValidator } from '@common/utils';

interface PhoneNumberProps {
  disabled?: boolean;
  label?: string;
}

const PhoneNumberField: FC<PhoneNumberProps> = ({ disabled, label = t('common.phoneNumber') }) => (
  <div className="field field-phone">
    <TextField disabled={disabled} label={label} name="phoneNumber" validate={phoneValidator} />
  </div>
);

export default PhoneNumberField;
