export const maskPercentage = (value: string): string => {
  if (value) {
    return parseFloat(value).toFixed().toString();
  }
  return value;
};

export const maskSalary = (value: number | string): string => {
  const DIVIDER = ',';
  const MAX_ORDER = 6;

  if (typeof value === 'number') {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, DIVIDER);
  }

  if (typeof value !== 'string') {
    return '';
  }

  let val = value.replace(/,/g, '');

  if (val.length > MAX_ORDER) {
    val = val.substring(0, val.length - 1);
  }

  if (Number.isNaN(+val)) {
    return '';
  }

  return val.replace(/\B(?=(\d{3})+(?!\d))/g, DIVIDER);
};
