/* istanbul ignore file */
import { useEffect } from 'react';
import { useFormApi } from 'informed';
import { t } from 'i18next';
import { IDropdownOption } from '@ui-modules/types';
import { Dropdown, TextField } from '@ui-modules/informed';
import { DriveFormSteps } from '@common/interfaces';
import { notEmptyValidator } from '@common/utils';
import '../styles/modal.scss';

interface FieldProps {
  fieldGroup?: string;
  options?: IDropdownOption[];
  returnTrip?: boolean;
}

const PickupTownField = ({ fieldGroup, options, returnTrip = false }: FieldProps) => {
  const { getFormState, setValue } = useFormApi();
  const formState = getFormState().values as any;
  const drives = formState?.[DriveFormSteps.Trip]?.driveList;
  const prevDropoffTown = drives ? drives[0]?.dropoffTown : null;
  const dropoffLocation = fieldGroup ? `${fieldGroup}.dropoffLocation` : 'dropoffLocation';
  const dropoffTown = fieldGroup ? `${fieldGroup}.dropoffTown` : 'dropoffTown';
  const pickupLocation = fieldGroup ? `${fieldGroup}.pickupLocation` : 'pickupLocation';

  useEffect(() => {
    if (returnTrip && prevDropoffTown) {
      setValue(fieldGroup ? `${fieldGroup}.pickupTown` : 'pickupTown', prevDropoffTown);
    }
  }, [fieldGroup, prevDropoffTown, returnTrip, setValue]);

  return (
    <div className="field" data-testid="planner-drive-form-field-pickup-town">
      {options ? (
        <Dropdown
          className="dropdown"
          isClearable
          isCreatable
          label={t('bookingDetails.pickupTown')}
          name="pickupTown"
          options={options}
          validate={notEmptyValidator}
          onChange={(option) => {
            setValue(pickupLocation, undefined);
            if (
              options?.length === 2 &&
              options?.find((i: IDropdownOption) => i.value === option.value)
            ) {
              setValue(dropoffLocation, undefined);
              setValue(
                dropoffTown,
                options?.find((i: IDropdownOption) => i.value !== option.value),
              );
            }
          }}
        />
      ) : (
        <TextField
          className="input"
          data-testid="planner-drive-form-field-pickup-town"
          label={t('bookingDetails.pickupTown')}
          name="pickupTown"
          validate={notEmptyValidator}
        />
      )}
    </div>
  );
};

export default PickupTownField;
